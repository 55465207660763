import moment from 'moment';
import { InputStreet } from '../generated/graphql';

import { chunk, range } from './array';

export type Month = {
  month: number
  year: number
  title: ''
  days?: {
    [key: string]: [{
      type: string
      areas: string[]
    }]
  }
}

export type Type = {
  id: string
  name: string
  description: string
  alarmDescription: string
  startTime?: string | null
  endTime?: string | null
  tag: string
  color: string
}

export type Year = {
  id: string;
  value: number;
  active?: boolean;
}

export type Area = {
  id: string
  name: string
  tag: string
}

export const csvJSON = (csvText, fields,  types: Type[], areas: Area[], year: number) => {
  const lines = [];
  let monthCounter = fields + 1;
  const linesArray = csvText.split('\n');

  // for trimming and deleting extra space
  linesArray.forEach((e: any) => {
      const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ';').trim();
      lines.push(row);
  });

  let months: Month[] = []

  linesArray.forEach((line, i) => {
    const lineArray = line.split(";");
    const chunkedLine = chunk(lineArray, monthCounter);
    [...chunkedLine].forEach((chunk, ci) => {
      if (i === 0) {
        const title = chunk[0]
        const daysInMonth = moment().set("year", moment().year() + 1).month(ci).daysInMonth();
        const daysArray = Array.from(Array(daysInMonth), (x,i) => [])
        const days = {};
        daysArray.forEach((_, i) => {
          Object.defineProperty(days, `${i + 1}`, {
            value: [],
            writable: true,
            configurable: true,
            enumerable: true
          });
        })
        months[ci] = {
          month: ci,
          year,
          title,
          days
        }
      } else {
        const day = chunk[0];
        const entryData = chunk.slice(1, chunk.length);
        entryData.forEach((data: string) => {
          if (data.length > 0) {
            const dataArray = data.split(" ");
            let entry = {
              type: '',
              areas: []
            }
            let type = types.find(t => {
              return data.includes(t.tag)
            });
            if (type) {
              entry.type = type.id;
              const index = dataArray.indexOf(type.tag);

              dataArray.splice(index, 1)
              const entryDataString = dataArray.join(" ").trim()
              const dataTo = entryDataString.split("-");
              const dataPlus = entryDataString.split("+");
              if (dataTo.length > 1) {
                const toRange = range(parseInt(dataTo[0].substring(1)), parseInt(dataTo[1]) + 1)
                const entryAreas = toRange.map(r => `B${r}`).filter(r => {
                  const ar = areas.find(a => a.tag === r);
                  return ar;
                }).map(r => areas.find(a => a.tag === r));
                if (entryAreas.length > 0) {
                  entry.areas = entryAreas;
                }
              } else if (dataPlus.length > 1) {
                const plusData = dataPlus;
                plusData[0] = dataPlus[0].substring(1);
                const entryAreas = plusData.map(r => `B${r}`).filter(r => {
                  const ar = areas.find(a => a.tag === r);
                  return ar;
                }).map(r => areas.find(a => a.tag === r));
                if (entryAreas.length > 0) {
                  entry.areas = entryAreas;
                }
              } else {
                const entryArea = areas.find(a => a.tag === entryDataString);
                if (entryArea) {
                  entry.areas = [entryArea];
                }
              }
            }

            if (entry.type.length > 0 && entry.areas.length > 0) {
              months[ci].days[`${day}`].push(entry);
            }
          }
        });
      }
    });
  });

  months.forEach(month => {
    Object.entries(month.days).forEach(([key, value]) => {
      if (value.length <= 0) {
        delete month.days[`${key}`];
      }
    });
  });

  return months;
}

export const readCSV = async (year: number, fields: number, file: File, types: Type[], areas: Area[]): Promise<Month[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const text = reader.result;
      resolve(csvJSON(text, fields, types, areas, year));
    };
    reader.onerror = reject;
  });
}

const parseStreets = (csvText) => {
  const lines: InputStreet[] = [];
  const linesArray = csvText.split('\n');

  linesArray.forEach((e: any) => {
      const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ';').trim();
      const item = row.split(';');
      lines.push({
        name: item[0],
        area: item[1]
      });
  });

  return lines;
}

export const readStreetsCSV = async (file: File): Promise<InputStreet[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const text = reader.result;
      resolve(parseStreets(text));
    };
    reader.onerror = reject;
  });
}