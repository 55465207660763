import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, Link, RouteComponentProps, useLocation, useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../logo.svg';

import {
  SettingFilled,
  LogoutOutlined,
  DashboardOutlined,
  UsergroupAddOutlined,
  FormOutlined
} from '@ant-design/icons';
import Button from 'antd/es/button';
import Layout from 'antd/es/layout';
import Menu from 'antd/es/menu';
import Modal from 'antd/es/modal';
import styles from './styles.module.scss';
import { getAccessToken, setAccessToken } from '../accessToken';
import { useLogoutMutation } from '../generated/graphql';
import { DataContext } from '../Context/DataContext';
import { Divider, notification, Popover, Tooltip } from 'antd';
import { Settings } from './Settings';

import { Dashboard } from './Components/Dashboard';
import { Areas } from './Components/Areas';
import { User } from './Components/User';
import { Types } from './Components/Types';
import { Streets } from './Components/Streets';
import { UserDashboard } from './Components/Dashboard/UserDashboard';
import { CalendarComponent } from './Components/Calendar';
import { Pattern } from './Components/Pattern';
import Login from '../Components/Login';

import { FaIcon } from '../Components/FaIcon';
import { faCalendar, faRoad, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import { faMap } from '@fortawesome/free-regular-svg-icons'

const { Header, Sider } = Layout;

export const Admin: React.FC<RouteComponentProps> = () => {
  const { me } = useContext(DataContext);
  const [collapsed, setCollapsed] = useState<boolean>(localStorage.getItem("collapsed") ? (localStorage.getItem("collapsed") === 'true') : false);
  const [logout, { client }] = useLogoutMutation();
  const [openLogin, setOpenLogin] = useState(true);
  const isLoggedIn: boolean = getAccessToken().length > 0 ? true : false;
  const location = useLocation();
  const history = useHistory()

  useEffect(() => {
    if (me && me.role !== "Admin") {
      setOpenLogin(true)
    }
    if (!me) {
      setOpenLogin(true)
    }
  }, [me]);

  const handleCollapse = (c) => {
    localStorage.setItem("collapsed", c);
    setCollapsed(c)
  }

  const handleLogout = async () => {
    const loggedOut = await logout();

    if (loggedOut.data.logout) {
      notification.success({
        message: 'Erfolgreich ausgeloggt!'
      });
      setAccessToken("");
      localStorage.setItem('jid', '');
      await client!.resetStore();
      window.location.reload();
    } else {
      notification.error({
        message: 'Ausloggen nicht möglich!'
      });
    }
  }

  return (
    <Layout>
      {me && (
        <>
          <Sider collapsible collapsed={collapsed} onCollapse={(c) => handleCollapse(c)} className={styles.sider}>
            <div className={styles.logo}>
              <Link to={{ pathname: '/' }}>
                <Logo fill={"#fff"} color={"#fff"} />
              </Link>
            </div>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]} selectedKeys={[location.pathname]}>
              <Menu.Item key="/userspace" icon={<DashboardOutlined translate={"no"} />}>
                <Link to={{ pathname: '/userspace' }}>Dashboard</Link>
              </Menu.Item>
              <Menu.Item key="/userspace/types" icon={<FaIcon icon={faTruckMoving} boxed={true} />} >
                <Link to={{ pathname: '/userspace/types' }}>Abfuhrtyp</Link>
              </Menu.Item>
              <Menu.Item key="/userspace/areas" icon={<FaIcon icon={faMap} boxed={true} />} >
                <Link to={{ pathname: '/userspace/areas' }}>Bereiche</Link>
              </Menu.Item>
              <Menu.Item key="/userspace/streets" icon={<FaIcon icon={faRoad} boxed={true} />} >
                <Link to={{ pathname: '/userspace/streets' }}>Straßen</Link>
              </Menu.Item>
              <Menu.Item key="/userspace/calendar" icon={<FaIcon icon={faCalendar} boxed={true} />} >
                <Link to={{ pathname: '/userspace/calendar' }}>Kalendar</Link>
              </Menu.Item>
              <Menu.Item key="/userspace/pattern" icon={<FormOutlined translate={'no'} />} >
                <Link to={{ pathname: '/userspace/pattern' }}>Vorlagen</Link>
              </Menu.Item>
            </Menu>

            {me.role === "Admin" && (
              <>
                <Divider />
                <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]} selectedKeys={[location.pathname]}>
                  <Menu.Item key="/admin" icon={<DashboardOutlined translate={"no"} />}>
                    <Link to={{ pathname: '/admin' }}>Dashboard</Link>
                  </Menu.Item>
                  <Menu.Item key="/admin/users" icon={<UsergroupAddOutlined translate={"no"} />}>
                    <Link to={{ pathname: '/admin/users' }}>Users</Link>
                  </Menu.Item>
                </Menu>
              </>
            )}
            <div className={styles.settings}>
              <Popover placement="rightBottom" title={"Settings"} content={<Settings />} trigger="click">
                <Button type="primary" shape="circle" className={styles.settingsButton} icon={<SettingFilled className={styles.settingsIcon} translate={'no'} />} />
              </Popover>
            </div>
          </Sider>
          <Layout className={styles.contentLayout}>
            <Header className={styles.header} style={{ padding: 0 }}>
              <div className={styles.userDetails}>
                {me.email} ({me.name} {me.lastname})
              </div>
              <Tooltip placement="left" title="Logout">
                <Button
                  type="primary"
                  className={styles.logout}
                  shape="circle"
                  onClick={handleLogout}
                  icon={<LogoutOutlined translate={'logout'} />}

                />
              </Tooltip>
            </Header>
            <Switch location={location} >
              <Route exact path='/userspace' component={UserDashboard} />
              <Route exact path='/userspace/streets' component={Streets} />
              <Route exact path='/userspace/types' component={Types} />
              <Route exact path='/userspace/areas' component={Areas} />
              <Route exact path='/userspace/calendar' component={CalendarComponent} />
              <Route exact path='/userspace/pattern' component={Pattern} />
              <Route exact path='/admin' component={Dashboard} />
              <Route exact path='/admin/users' component={User} />
            </Switch>
          </Layout>
        </>
      )}
      {!isLoggedIn && (
        <Modal
          title="Login"
          visible={openLogin}
          footer={null}
          width={'350px'}
          onCancel={() => history.push('/')}
        >
          <Login close={() => {
            setOpenLogin(false)
          }} />
        </Modal>
      )}
    </Layout >
  );
}
