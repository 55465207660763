import React, { useState } from 'react';
import { Button, Input, List, notification, TimePicker } from 'antd';
import moment, { Moment } from 'moment';
import localeDe from 'antd/es/date-picker/locale/de_DE';
import styles from './batch.module.scss';
import { InputEvent, useUpdateEventsMutation } from '../../../generated/graphql';
import { Event } from '../../../Utils/cal';

interface BatchEventsProps {
  events: Event[]
  reset: Function
}

export const BatchEvents: React.FC<BatchEventsProps> = ({ events, reset }) => {
  const [updateEvents] = useUpdateEventsMutation()
  const [description, setDescription] = useState<string>();
  const [alarmDescription, setAlarmDescription] = useState<string>()
  const [startTime, setStartTime] = useState<Moment>();
  const [endTime, setEndTime] = useState<Moment>();
  const format = 'HH:mm';

  const getDisabledHours = () => {
    const hours: number[] = [];
    for(let i = 0; i < (startTime ? startTime.hour() : moment().hour()); i++){
      hours.push(i);
    }
    return hours;
  }

  const handleBatchUpdate = async () => {
    const year = events[0].year.id;
    let start: string | null = null;
    let end: string | null = null;

    if (startTime && endTime) {
      start = startTime.format(format);
      end = endTime.format(format);
    }

    const inputEvents: InputEvent[] = [];

    events.forEach(async (event) => {
      const newEvent = {
        ...event,
        areas: event.areas.map(a => a.id),
        type: event.type.id,
        description: description ? description : event.description,
        alarmDescription: alarmDescription ? alarmDescription : event.alarmDescription,
        startTime: start ? start : event.startTime,
        endTime: end ? end : event.endTime
      }
      delete newEvent.year;
      delete newEvent['__typename'];
      inputEvents.push(newEvent);
    });

    const createdEvents = await updateEvents({
      variables: {
        events: inputEvents,
        year: year
      }
    });

    if (createdEvents.data.updateEvents) {
      notification.success({
        message: "Einträge erfolgreich geupdated!"
      });
      setDescription(undefined);
      setAlarmDescription(undefined);
      setStartTime(undefined);
      setEndTime(undefined);
      reset();
    } else {
      notification.error({
        message: `Ein Fehler ist aufgetreten!`
      });
    }
  }

  return (
    <List bordered className={styles.batchHandler} size={'large'}>
      <List.Item>
        <div className={styles.grid}>
          <div style={{ marginRight: '.5rem' }}>Stapelbearbeitung:</div>
          <Input
            value={description}
            onChange={({target}) => setDescription(target.value)}
            placeholder={'Beschreibung'}
            className={styles.input}
          />
          <Input
            value={alarmDescription}
            onChange={({target}) => setAlarmDescription(target.value)}
            placeholder={'Alarmbeschreibung'}
            className={styles.input}
          />
          <TimePicker
            className={styles.time}
            locale={localeDe}
            minuteStep={15}
            showNow={false}
            format={format}
            value={startTime ? startTime : null}
            placeholder={'Startzeit'}
            onChange={(value) => setStartTime(value)}
          />
          <TimePicker
            className={styles.time}
            locale={localeDe}
            minuteStep={15}
            showNow={false}
            format={format}
            disabledHours={getDisabledHours}
            value={endTime ? endTime : null}
            placeholder={'Endzeit'}
            onChange={(value) => setEndTime(value)}
          />
          <Button onClick={handleBatchUpdate} type={'primary'}>Speichern</Button>
        </div>
        <div>Alle ausgewählten Einträge werden mit den hier eingetragenen Daten überschrieben, sofern befüllt.</div>
      </List.Item>
    </List>
  );
}