import React from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { DataProvider } from './Context/DataContext'
import Home from "./Pages/Home/";
import { CalenderExport } from "./Pages/Export/";
import { Admin } from "./Admin";
import Verify from "./Pages/Verify";

export const Routes: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <DataProvider location={location} history={history}>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/cex/:id" component={CalenderExport} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/admin/:id" component={Admin} />
          <Route exact path="/userspace" component={Admin} />
          <Route exact path="/userspace/:id" component={Admin} />
          <Route exact path="/user/verify/:token" component={Verify} />
        </Switch>
      </div>
    </DataProvider>
  );
};
