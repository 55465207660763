import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import Loader from '../../../Components/Loader';
import styles from './styles.module.scss';
import {
  SyncOutlined,
} from '@ant-design/icons';
import { useAreasQuery, useStreetsQuery, useTypesQuery } from '../../../generated/graphql';

const { Title } = Typography;

export const UserDashboard: React.FC = () => {
  const { data: typesData, refetch: refetchTypes, loading: loadingTypes } = useTypesQuery({ fetchPolicy: "network-only" });
  const { data: areaData, refetch: refetchAreas, loading: loadingAreas } = useAreasQuery({ fetchPolicy: "network-only" });
  const { data: streetsData, refetch: refetchStreets, loading: loadingStreets } = useStreetsQuery({ fetchPolicy: "network-only" });

  return (
    <Row gutter={[32, 32]} className={styles.row}>
      <Col xl={8} sm={12} xs={32} className={styles.col}>
        <Card
          title={"Abfuhrtypen"}
          headStyle={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            color: "#003363"
          }}
          extra={<SyncOutlined translate={"no"} spin={loadingTypes} onClick={() => refetchTypes()} className={styles.link} />}
          className={styles.content}
          bordered={false}
        >
          <div className={styles.center}>
            {loadingTypes && <Loader />}
            {!loadingTypes && typesData && (
              <Title className={styles.headline}>
                {typesData && typesData.types.length}
              </Title>
            )}
          </div>
        </Card>
      </Col>
      <Col xl={8} sm={12} xs={32} className={styles.col}>
        <Card
          title={"Abfuhrbereiche"}
          headStyle={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            color: "#003363"
          }}
          extra={<SyncOutlined translate={"no"} spin={loadingAreas} onClick={() => refetchAreas()} className={styles.link} />}
          className={styles.content}
          bordered={false}
        >
          <div className={styles.center}>
            {loadingAreas && <Loader />}
            {!loadingAreas && areaData && (
              <Title className={styles.headline}>
                {areaData && areaData.areas.length}
              </Title>
            )}
          </div>
        </Card>
      </Col>
      <Col xl={8} sm={12} xs={32} className={styles.col}>
        <Card
          title={"Straßen"}
          headStyle={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            color: "#003363"
          }}
          extra={<SyncOutlined translate={"no"} spin={loadingStreets} onClick={() => refetchStreets()} className={styles.link} />}
          className={styles.content}
          bordered={false}
        >
          <div className={styles.center}>
            {loadingStreets && <Loader />}
            {!loadingStreets && streetsData && (
              <Title className={styles.headline}>
                {streetsData && streetsData.streets.length}
              </Title>
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
}
