import React, { useState } from 'react';
import { Button, Card } from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  CloseOutlined
} from '@ant-design/icons';
import styles from './streets.module.scss';
import { CreateStreet } from './CreateStreet';
import { useStreetsQuery } from '../../../generated/graphql';
import { StreetsTable } from './StreetsTable';
import { ImportStreets } from './ImportStreets';
import { FaIcon } from '../../../Components/FaIcon';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';

export const Streets: React.FC = () => {
  const [open, set] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [street, setStreet] = useState();
  const { data, refetch, loading} = useStreetsQuery({ fetchPolicy: 'network-only' })

  const reset = () => {
    setStreet(null);
    set(false);
    refetch();
  }

  const handleEdit = (str) => {
    setStreet(str);
    set(true);
  }

  return (
    <Card
      title={"Straßen"}
      headStyle={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#003363"
      }}
      extra={
        <div>
          <Button shape={"circle"} className={styles.importButton} type="primary" onClick={() => {
            set(false);
            setOpenImport(c => !c)
          }}>
            {!openImport ? <FaIcon icon={faFileImport} /> : <CloseOutlined translate={'no'} />}
          </Button>
          <Button shape={"circle"} className={styles.button} type="primary" onClick={() => {
            setOpenImport(false);
            set(c => !c)
          }}>
            {!open ? <PlusOutlined translate={'no'} /> : <MinusOutlined translate={'no'} />}
          </Button>
        </div>
      }
      className={styles.content}
      bordered={false}
    >
      {open && <CreateStreet street={street} reset={reset} />}
      {openImport && <ImportStreets reset={reset} />}
      {data && <StreetsTable streets={data.streets} refetch={refetch} loading={loading} edit={handleEdit} />}
    </Card>
  );
}