import React, { useRef, useState } from 'react';
import moment, {Moment} from 'moment';
import localeDe from 'antd/es/date-picker/locale/de_DE';
import { Event } from '../../../Utils/cal';
import { Area, Type } from '../../../Utils/csv';
import styles from './calendar.module.scss';
import tableStyles from "../Utils/table.module.scss";
import { Button, Input, List, notification, Popconfirm, TimePicker, Tooltip, Typography } from 'antd';
import {
  DeleteOutlined,
} from '@ant-design/icons';
import { ListHeader } from './ListHeader';
import { TableComponent } from '../Utils/TableComponent';
import { columnSearchProps } from '../Utils/table';
import { useRemoveEventMutation, useUpdateEventMutation } from '../../../generated/graphql';
import { BatchEvents } from './BatchEvents';

interface EventListProps {
  types: Type[]
  areas: Area[]
  actualType: string[]
  actualArea: string
  setType: Function
  setArea: Function
  events: Event[]
  reset: Function
  loading: boolean
}

type UpdateInput =  {
  event: Event
  property: 'description' | 'alarmDescription' | 'startTime' | 'endTime'
  value: string | Moment
}

const { Text } = Typography;

export const EventList: React.FC<EventListProps> = ({
  types,
  areas,
  events,
  reset,
  loading,
  actualArea,
  actualType,
  setType,
  setArea
}) => {
  const [selected, setSelected] = useState<Event[]>([]);
  const [updateEvent] = useUpdateEventMutation()
  const [removeEvent] = useRemoveEventMutation()
  // const [expandable] = useState(true);
  const [query, setQuery] = useState("");
  const [column, setColumn] = useState("");
  const searchRef = useRef<Input>(null);
  const format = 'HH:mm';

  const updateSingleEvent = async ({event, value, property}: UpdateInput) => {
    const inputAreas = event.areas.map(a => a.id);
    const inputType = event.type.id
    const newEvent = {
      ...event,
      areas: inputAreas,
      type: inputType,
      [property]: value
    }
    delete newEvent.year;
    delete newEvent['__typename'];

    const updated = await updateEvent({
      variables: {
        event: newEvent,
        id: event.id
      }
    });

    if (updated.data.updateEvent) {
      notification.success({
        message: 'Eintrag erfolgreich berabeitet'
      });
      reset();
    } else {
      notification.error({
        message: 'Eintrag konnte nicht gespeichert werden.'
      });
    }
  }

  const remove = async (item: Event) => {
    const removed = await removeEvent({
      variables: {
        id: item.id
      }
    })
    if (removed.data.removeEvent) {
      notification.success({
        message: `Eintrag ${item.name} wurde erfolgreich entfernt!`
      })
      reset();
    } else {
      notification.error({
        message: 'Eintrag konnte nicht entferent werden!'
      })
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      ...columnSearchProps('name', searchRef, column, query, setColumn, setQuery),
    },
    {
      title: 'Datum',
      dataIndex: 'day',
      width: 150,
      key: 'day',
      render: (_, event: Event) => {
        const currentMoment = moment([event.year.value, event.month, event.day]);

        return <strong>{currentMoment.format('ddd') + ' ' + currentMoment.format('DD.MM.YYYY')}</strong>
      }
    },
    {
      title: 'Beschreibung',
      dataIndex: 'description',
      key: 'description',
      render: (_, event: Event) => {
        const type = types.find(t => t.id === event.type.id);
        return (
          <Text editable={{ tooltip: 'Bearbeiten', onChange: (value) => updateSingleEvent({event, value, property: 'description'}) }}>
            {event.description ? event.description : type.description}
          </Text>
        );
      }
    },
    {
      title: 'Alarmbeschreibung',
      dataIndex: 'alarmDescription',
      key: 'alarmDescription',
      render: (_, event: Event) => {
        const type = types.find(t => t.id === event.type.id);
        return (
          <Text editable={{ tooltip: 'Bearbeiten', onChange: (value) => updateSingleEvent({event, value, property: 'alarmDescription'}) }}>
            {event.alarmDescription ? event.alarmDescription : type.alarmDescription}
          </Text>
        );
      }
    },
    {
      title: 'Start',
      dataIndex: 'startTime',
      width: 100,
      key: 'startTime',
      render: (_, event: Event) => {
        const type = types.find(t => t.id === event.type.id);
        const startTime = event.startTime ? event.startTime : type.startTime;
        return (
          <TimePicker
            locale={localeDe}
            minuteStep={15}
            showNow={false}
            format={format}
            allowClear={false}
            placeholder={'Startzeit'}
            onChange={(v) => updateSingleEvent({event, value: v.format(format), property: 'startTime'})}
            defaultValue={startTime ? moment().set({hours: parseInt(startTime.split(':')[0]), minutes: parseInt(startTime.split(':')[1])}) : null}
          />
        );
      }
    },
    {
      title: 'Ende',
      dataIndex: 'endTime',
      width: 100,
      key: 'endTime',
      render: (_, event: Event) => {
        const type = types.find(t => t.id === event.type.id);
        const endTime = event.endTime ? event.endTime : type.endTime;
        return (
          <TimePicker
            locale={localeDe}
            minuteStep={15}
            showNow={false}
            format={format}
            allowClear={false}
            placeholder={'Endzeit'}
            onChange={(v) => updateSingleEvent({event, value: v.format(format), property: 'endTime'})}
            defaultValue={endTime ? moment().set({hours: parseInt(endTime.split(':')[0]), minutes: parseInt(endTime.split(':')[1])}) : null}
          />
        );
      }
    },
    {
      title: () => <div className={tableStyles.centerTitle}>Aktionen</div>,
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (_: number, record) => (
        <div className={tableStyles.centerWrapper}>
          <Tooltip title={"Eintrag entfernen"}>
            <Popconfirm
              title="Wollen Sie diesen Eintrag wirklich entfernen?"
              style={{ color: 'red' }}
              onConfirm={() => remove(record)}
              placement="left"
              okText="Entfernen"
              cancelText="Abbrechen"
            >
              <Button
                danger
                shape={"circle"}
                type={"primary"}
                size={'small'}
                className={tableStyles.actionButton}
                icon={<DeleteOutlined translate={'no'} />}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      )
    }
  ]

  // const handleArea = (value) => {
  //   // console.log(value)
  // }

  const handleReset = async () => {
    await setSelected([]);
    await reset()
  }

  return (
    <div className={styles.list}>
      <List bordered className={styles.filterContainer} size={'large'}>
        <List.Item>
          <ListHeader
            types={types}
            areas={areas}
            actualType={actualType}
            actualArea={actualArea}
            setType={setType}
            setArea={setArea}
            reset={reset}
          />
        </List.Item>
      </List>
      <TableComponent
        rowSelection
        selected={setSelected}
        selectedItems={selected.map(s => s.id)}
        loading={loading}
        columns={columns}
        bordered={true}
        pagination={{ showSizeChanger: true }}
        data={events.sort((a, b) => a.day - b.day).sort((a, b) => a.month - b.month)}
        // expandable={{
        //   expandedRowRender: (record: Event) => (
        //     <div style={{ margin: 0 }}>
        //       <Select
        //         className={styles.select}
        //         aria-required={true}
        //         placeholder={'Bereiche wählen'}
        //         onChange={(value) => handleArea(value)}
        //         mode={'multiple'}
        //         value={record.areas.map(a => a.id)}
        //       >
        //         {areas.map(area => (
        //           <Select.Option key={area.id} value={area.id} name={area.name}>{area.name}</Select.Option>
        //         ))}
        //       </Select>
        //     </div>
        //   ),
        //   indentSize: 1,
        //   expandIconColumnIndex: 7,
        //   rowExpandable: () => expandable,
        // }}
      />
      {selected.length > 0 && (
        <BatchEvents events={selected} reset={handleReset} />
      )}
    </div>
  );
}
