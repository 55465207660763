import React from 'react';
import styles from './verify.module.scss';
import { Link, useParams } from 'react-router-dom';
import { useVerifyUserQuery } from '../../generated/graphql';
import Loader from '../../Components/Loader';
import { Button } from 'antd';

const Verify: React.FC = () => {
  // @ts-ignore
  const { token } = useParams()
  const { data, loading } = useVerifyUserQuery({ variables: { token } });

  const success = <p>Your account was successfully verified!<br />You can now login to your account.</p>;
  const error = <p className={styles.error}>Etwas ist schief gelaufen.<br />Bitte prüfen Sie Ihren Verifizierungslink.</p>;

  const text = data && data.verifyUser ? success : error;

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.verifyContainer}>
          {data && !loading ? (
            text
           ) : (
            <Loader />
           )}
        </div>
        <Link to={{pathname: '/'}}><Button type={'primary'}>Zur Homepage</Button></Link>
      </div>
    </div>
  );
}

export default Verify;