import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Area = {
  __typename?: 'Area';
  id: Scalars['String'];
  name: Scalars['String'];
  tag: Scalars['String'];
  streets: Array<Street>;
  user: User;
};

export type CalendarEntry = {
  __typename?: 'CalendarEntry';
  id: Scalars['String'];
  month: Scalars['Int'];
  title: Scalars['String'];
  days?: Maybe<Scalars['JSON']>;
  year: Scalars['Int'];
  user: User;
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  alarmDescription?: Maybe<Scalars['String']>;
  day: Scalars['Int'];
  month: Scalars['Int'];
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  year: Year;
  type: Type;
  areas: Array<Area>;
  user: User;
};

export type InputEvent = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  areas: Array<Scalars['String']>;
  type: Scalars['String'];
  day: Scalars['Int'];
  month: Scalars['Int'];
  endTime?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  alarmDescription?: Maybe<Scalars['String']>;
};

export type InputStreet = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  area: Scalars['String'];
};


export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  logout: Scalars['Boolean'];
  revokeRefreshTokensForUser: Scalars['Boolean'];
  updateRole: Scalars['Boolean'];
  updateVerify: Scalars['Boolean'];
  updateUser: Scalars['Boolean'];
  login: LoginResponse;
  register: Scalars['Boolean'];
  removeUser: Scalars['Boolean'];
  createArea: Scalars['Boolean'];
  updateArea: Scalars['Boolean'];
  removeArea: Scalars['Boolean'];
  updateSetting: Scalars['Boolean'];
  createStreet: Scalars['Boolean'];
  createStreets: Scalars['Boolean'];
  updateStreet: Scalars['Boolean'];
  removeStreet: Scalars['Boolean'];
  createType: Scalars['Boolean'];
  updateType: Scalars['Boolean'];
  removeType: Scalars['Boolean'];
  createCalEntry: Scalars['Boolean'];
  updateCalEntry: Scalars['Boolean'];
  removeCalEntry: Scalars['Boolean'];
  removeCalMonth: Scalars['Boolean'];
  removeCalYear: Scalars['Boolean'];
  createYear: Scalars['String'];
  toggleYear: Scalars['Boolean'];
  removeYear: Scalars['Boolean'];
  createEvents: Scalars['Boolean'];
  createEvent: Scalars['Boolean'];
  updateEvent: Scalars['Boolean'];
  updateEvents: Scalars['Boolean'];
  removeEvent: Scalars['Boolean'];
  removeEventsByMonth: Scalars['Boolean'];
  removeEventsByYear: Scalars['Boolean'];
  createPattern: Scalars['Boolean'];
  updatePattern: Scalars['Boolean'];
  removePattern: Scalars['Boolean'];
};


export type MutationRevokeRefreshTokensForUserArgs = {
  userId: Scalars['String'];
};


export type MutationUpdateRoleArgs = {
  role: Scalars['String'];
  id: Scalars['String'];
};


export type MutationUpdateVerifyArgs = {
  verified: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  dsgvo: Scalars['Boolean'];
  email: Scalars['String'];
  lastname: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};


export type MutationRegisterArgs = {
  role: Scalars['String'];
  password: Scalars['String'];
  dsgvo: Scalars['Boolean'];
  email: Scalars['String'];
  lastname: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['String'];
};


export type MutationCreateAreaArgs = {
  tag: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateAreaArgs = {
  tag: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveAreaArgs = {
  id: Scalars['String'];
};


export type MutationUpdateSettingArgs = {
  value: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationCreateStreetArgs = {
  areaId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateStreetsArgs = {
  streets: Array<InputStreet>;
};


export type MutationUpdateStreetArgs = {
  areaId: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveStreetArgs = {
  id: Scalars['String'];
};


export type MutationCreateTypeArgs = {
  color: Scalars['String'];
  tag: Scalars['String'];
  endTime?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  alarmDescription: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateTypeArgs = {
  color: Scalars['String'];
  tag: Scalars['String'];
  endTime?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  alarmDescription: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveTypeArgs = {
  id: Scalars['String'];
};


export type MutationCreateCalEntryArgs = {
  year: Scalars['Int'];
  days: Scalars['JSON'];
  month: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationUpdateCalEntryArgs = {
  days: Scalars['JSON'];
  month: Scalars['Int'];
  title: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemoveCalEntryArgs = {
  id: Scalars['String'];
};


export type MutationRemoveCalMonthArgs = {
  id: Scalars['Float'];
};


export type MutationRemoveCalYearArgs = {
  id: Scalars['Float'];
};


export type MutationCreateYearArgs = {
  value: Scalars['Float'];
};


export type MutationToggleYearArgs = {
  active: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationRemoveYearArgs = {
  id: Scalars['String'];
};


export type MutationCreateEventsArgs = {
  events: Array<InputEvent>;
  year: Scalars['String'];
};


export type MutationCreateEventArgs = {
  event: InputEvent;
  year: Scalars['Int'];
};


export type MutationUpdateEventArgs = {
  event: InputEvent;
  id: Scalars['String'];
};


export type MutationUpdateEventsArgs = {
  events: Array<InputEvent>;
  year: Scalars['String'];
};


export type MutationRemoveEventArgs = {
  id: Scalars['String'];
};


export type MutationRemoveEventsByMonthArgs = {
  year: Scalars['String'];
  month: Scalars['Int'];
};


export type MutationRemoveEventsByYearArgs = {
  year: Scalars['String'];
};


export type MutationCreatePatternArgs = {
  number: Scalars['Int'];
  year: Scalars['String'];
};


export type MutationUpdatePatternArgs = {
  number: Scalars['Int'];
  year: Scalars['String'];
  id: Scalars['String'];
};


export type MutationRemovePatternArgs = {
  id: Scalars['String'];
};

export type Pattern = {
  __typename?: 'Pattern';
  id: Scalars['String'];
  year: Scalars['String'];
  number: Scalars['Float'];
  user: User;
};

export type Query = {
  __typename?: 'Query';
  users: Array<User>;
  me?: Maybe<User>;
  verifyUser: Scalars['Boolean'];
  entryAreas: Array<Area>;
  areas: Array<Area>;
  settings: Array<Settings>;
  streets: Array<Street>;
  entryStreets: Array<Street>;
  entryTypes: Array<Type>;
  types: Array<Type>;
  entries: Array<CalendarEntry>;
  calEntries: Array<CalendarEntry>;
  entryYears: Array<Year>;
  years: Array<Year>;
  entryEvents: Array<Event>;
  events: Array<Event>;
  entryPatterns: Array<Pattern>;
  patterns: Array<Pattern>;
};


export type QueryVerifyUserArgs = {
  token: Scalars['String'];
};


export type QueryEntryAreasArgs = {
  id: Scalars['String'];
};


export type QueryEntryStreetsArgs = {
  id: Scalars['String'];
};


export type QueryEntryTypesArgs = {
  id: Scalars['String'];
};


export type QueryEntriesArgs = {
  id: Scalars['String'];
};


export type QueryEntryYearsArgs = {
  id: Scalars['String'];
};


export type QueryEntryEventsArgs = {
  id: Scalars['String'];
};


export type QueryEntryPatternsArgs = {
  id: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Street = {
  __typename?: 'Street';
  id: Scalars['String'];
  name: Scalars['String'];
  area: Area;
  user: User;
};

export type Type = {
  __typename?: 'Type';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  alarmDescription: Scalars['String'];
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  tag: Scalars['String'];
  user: User;
  events?: Maybe<Array<Event>>;
};

export type UpdateRoleInput = {
  role: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  name: Scalars['String'];
  lastname: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
  dsgvo: Scalars['Boolean'];
  verified: Scalars['Boolean'];
  verificationToken: Scalars['String'];
  role: Scalars['String'];
  areas: Array<Area>;
  years: Array<Year>;
  events: Array<Event>;
  types: Array<Type>;
  streets: Array<Street>;
  entries: Array<CalendarEntry>;
  patterns: Array<Pattern>;
};

export type Year = {
  __typename?: 'Year';
  id: Scalars['String'];
  value: Scalars['Int'];
  active?: Maybe<Scalars['Boolean']>;
  events?: Maybe<Array<Event>>;
  user: User;
};

export type AreasQueryVariables = Exact<{ [key: string]: never; }>;


export type AreasQuery = (
  { __typename?: 'Query' }
  & { areas: Array<(
    { __typename?: 'Area' }
    & Pick<Area, 'id' | 'name' | 'tag'>
  )> }
);

export type CreateAreaMutationVariables = Exact<{
  name: Scalars['String'];
  tag: Scalars['String'];
}>;


export type CreateAreaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createArea'>
);

export type EntryAreasQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EntryAreasQuery = (
  { __typename?: 'Query' }
  & { entryAreas: Array<(
    { __typename?: 'Area' }
    & Pick<Area, 'id' | 'name' | 'tag'>
  )> }
);

export type RemoveAreaMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveAreaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeArea'>
);

export type UpdateAreaMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  tag: Scalars['String'];
}>;


export type UpdateAreaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateArea'>
);

export type CalEntriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CalEntriesQuery = (
  { __typename?: 'Query' }
  & { calEntries: Array<(
    { __typename?: 'CalendarEntry' }
    & Pick<CalendarEntry, 'id' | 'title' | 'month' | 'year' | 'days'>
  )> }
);

export type CreateCalEntryMutationVariables = Exact<{
  title: Scalars['String'];
  month: Scalars['Int'];
  days: Scalars['JSON'];
  year: Scalars['Int'];
}>;


export type CreateCalEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCalEntry'>
);

export type EntriesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EntriesQuery = (
  { __typename?: 'Query' }
  & { entries: Array<(
    { __typename?: 'CalendarEntry' }
    & Pick<CalendarEntry, 'id' | 'title' | 'month' | 'year' | 'days'>
  )> }
);

export type RemoveCalEntryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveCalEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCalEntry'>
);

export type RemoveCalMonthMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type RemoveCalMonthMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCalMonth'>
);

export type RemoveCalYearMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type RemoveCalYearMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCalYear'>
);

export type UpdateCalEntryMutationVariables = Exact<{
  id: Scalars['String'];
  title: Scalars['String'];
  month: Scalars['Int'];
  days: Scalars['JSON'];
}>;


export type UpdateCalEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCalEntry'>
);

export type CreateEventMutationVariables = Exact<{
  event: InputEvent;
  year: Scalars['Int'];
}>;


export type CreateEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createEvent'>
);

export type CreateEventsMutationVariables = Exact<{
  events: Array<InputEvent>;
  year: Scalars['String'];
}>;


export type CreateEventsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createEvents'>
);

export type EntryEventsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EntryEventsQuery = (
  { __typename?: 'Query' }
  & { entryEvents: Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'description' | 'alarmDescription' | 'day' | 'month' | 'startTime' | 'endTime'>
    & { year: (
      { __typename?: 'Year' }
      & Pick<Year, 'id' | 'active' | 'value'>
    ), type: (
      { __typename?: 'Type' }
      & Pick<Type, 'id'>
    ), areas: Array<(
      { __typename?: 'Area' }
      & Pick<Area, 'id' | 'name'>
    )> }
  )> }
);

export type EventsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsQuery = (
  { __typename?: 'Query' }
  & { events: Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'description' | 'alarmDescription' | 'day' | 'month' | 'startTime' | 'endTime'>
    & { year: (
      { __typename?: 'Year' }
      & Pick<Year, 'id' | 'value'>
    ), type: (
      { __typename?: 'Type' }
      & Pick<Type, 'id'>
    ), areas: Array<(
      { __typename?: 'Area' }
      & Pick<Area, 'id' | 'name'>
    )> }
  )> }
);

export type RemoveEventsByYearMutationVariables = Exact<{
  year: Scalars['String'];
}>;


export type RemoveEventsByYearMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeEventsByYear'>
);

export type RemoveEventMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeEvent'>
);

export type RemoveEventsByMonthMutationVariables = Exact<{
  month: Scalars['Int'];
  year: Scalars['String'];
}>;


export type RemoveEventsByMonthMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeEventsByMonth'>
);

export type UpdateEventMutationVariables = Exact<{
  event: InputEvent;
  id: Scalars['String'];
}>;


export type UpdateEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEvent'>
);

export type UpdateEventsMutationVariables = Exact<{
  events: Array<InputEvent>;
  year: Scalars['String'];
}>;


export type UpdateEventsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEvents'>
);

export type CreatePatternMutationVariables = Exact<{
  year: Scalars['String'];
  number: Scalars['Int'];
}>;


export type CreatePatternMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPattern'>
);

export type PatternsQueryVariables = Exact<{ [key: string]: never; }>;


export type PatternsQuery = (
  { __typename?: 'Query' }
  & { patterns: Array<(
    { __typename?: 'Pattern' }
    & Pick<Pattern, 'id' | 'year' | 'number'>
  )> }
);

export type RemovePatternMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemovePatternMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removePattern'>
);

export type UpdatePatternMutationVariables = Exact<{
  id: Scalars['String'];
  year: Scalars['String'];
  number: Scalars['Int'];
}>;


export type UpdatePatternMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePattern'>
);

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = (
  { __typename?: 'Query' }
  & { settings: Array<(
    { __typename?: 'Settings' }
    & Pick<Settings, 'id' | 'name' | 'value'>
  )> }
);

export type UpdateSettingMutationVariables = Exact<{
  id: Scalars['Float'];
  value: Scalars['String'];
}>;


export type UpdateSettingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSetting'>
);

export type CreateStreetMutationVariables = Exact<{
  name: Scalars['String'];
  areaId: Scalars['String'];
}>;


export type CreateStreetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStreet'>
);

export type CreateStreetsMutationVariables = Exact<{
  streets: Array<InputStreet>;
}>;


export type CreateStreetsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStreets'>
);

export type EntryStreetsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EntryStreetsQuery = (
  { __typename?: 'Query' }
  & { entryStreets: Array<(
    { __typename?: 'Street' }
    & Pick<Street, 'id' | 'name'>
  )> }
);

export type RemoveStreetMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveStreetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeStreet'>
);

export type StreetsQueryVariables = Exact<{ [key: string]: never; }>;


export type StreetsQuery = (
  { __typename?: 'Query' }
  & { streets: Array<(
    { __typename?: 'Street' }
    & Pick<Street, 'id' | 'name'>
    & { area: (
      { __typename?: 'Area' }
      & Pick<Area, 'id' | 'name'>
    ) }
  )> }
);

export type UpdateStreetMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  areaId: Scalars['String'];
}>;


export type UpdateStreetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStreet'>
);

export type CreateTypeMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  alarmDescription: Scalars['String'];
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  color: Scalars['String'];
}>;


export type CreateTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createType'>
);

export type EntryTypesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EntryTypesQuery = (
  { __typename?: 'Query' }
  & { entryTypes: Array<(
    { __typename?: 'Type' }
    & Pick<Type, 'id' | 'name' | 'description' | 'alarmDescription' | 'startTime' | 'endTime' | 'tag' | 'color'>
  )> }
);

export type RemoveTypeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeType'>
);

export type TypesQueryVariables = Exact<{ [key: string]: never; }>;


export type TypesQuery = (
  { __typename?: 'Query' }
  & { types: Array<(
    { __typename?: 'Type' }
    & Pick<Type, 'id' | 'name' | 'description' | 'alarmDescription' | 'startTime' | 'endTime' | 'tag' | 'color'>
  )> }
);

export type UpdateTypeMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  alarmDescription: Scalars['String'];
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  color: Scalars['String'];
}>;


export type UpdateTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateType'>
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken' | 'refreshToken'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'lastname' | 'email' | 'verified' | 'dsgvo' | 'role'>
    ) }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'lastname' | 'email' | 'dsgvo' | 'verified' | 'role'>
  )> }
);

export type RegisterMutationVariables = Exact<{
  name: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  dsgvo: Scalars['Boolean'];
  password: Scalars['String'];
  role: Scalars['String'];
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'register'>
);

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUser'>
);

export type RevokeTokenMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type RevokeTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeRefreshTokensForUser'>
);

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['String'];
  role: Scalars['String'];
}>;


export type UpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRole'>
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  dsgvo: Scalars['Boolean'];
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUser'>
);

export type UpdateVerifyMutationVariables = Exact<{
  id: Scalars['String'];
  verified: Scalars['Boolean'];
}>;


export type UpdateVerifyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateVerify'>
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'lastname' | 'email' | 'dsgvo' | 'verified' | 'role'>
  )> }
);

export type VerifyUserQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verifyUser'>
);

export type ToggleYearMutationVariables = Exact<{
  id: Scalars['String'];
  active: Scalars['Boolean'];
}>;


export type ToggleYearMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleYear'>
);

export type CreateYearMutationVariables = Exact<{
  value: Scalars['Float'];
}>;


export type CreateYearMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createYear'>
);

export type YearsQueryVariables = Exact<{ [key: string]: never; }>;


export type YearsQuery = (
  { __typename?: 'Query' }
  & { years: Array<(
    { __typename?: 'Year' }
    & Pick<Year, 'id' | 'active' | 'value'>
  )> }
);


export const AreasDocument = gql`
    query Areas {
  areas {
    id
    name
    tag
  }
}
    `;

/**
 * __useAreasQuery__
 *
 * To run a query within a React component, call `useAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useAreasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AreasQuery, AreasQueryVariables>) {
        return ApolloReactHooks.useQuery<AreasQuery, AreasQueryVariables>(AreasDocument, baseOptions);
      }
export function useAreasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AreasQuery, AreasQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AreasQuery, AreasQueryVariables>(AreasDocument, baseOptions);
        }
export type AreasQueryHookResult = ReturnType<typeof useAreasQuery>;
export type AreasLazyQueryHookResult = ReturnType<typeof useAreasLazyQuery>;
export type AreasQueryResult = ApolloReactCommon.QueryResult<AreasQuery, AreasQueryVariables>;
export const CreateAreaDocument = gql`
    mutation CreateArea($name: String!, $tag: String!) {
  createArea(name: $name, tag: $tag)
}
    `;
export type CreateAreaMutationFn = ApolloReactCommon.MutationFunction<CreateAreaMutation, CreateAreaMutationVariables>;

/**
 * __useCreateAreaMutation__
 *
 * To run a mutation, you first call `useCreateAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAreaMutation, { data, loading, error }] = useCreateAreaMutation({
 *   variables: {
 *      name: // value for 'name'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useCreateAreaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAreaMutation, CreateAreaMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAreaMutation, CreateAreaMutationVariables>(CreateAreaDocument, baseOptions);
      }
export type CreateAreaMutationHookResult = ReturnType<typeof useCreateAreaMutation>;
export type CreateAreaMutationResult = ApolloReactCommon.MutationResult<CreateAreaMutation>;
export type CreateAreaMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAreaMutation, CreateAreaMutationVariables>;
export const EntryAreasDocument = gql`
    query EntryAreas($id: String!) {
  entryAreas(id: $id) {
    id
    name
    tag
  }
}
    `;

/**
 * __useEntryAreasQuery__
 *
 * To run a query within a React component, call `useEntryAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntryAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntryAreasQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntryAreasQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryAreasQuery, EntryAreasQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryAreasQuery, EntryAreasQueryVariables>(EntryAreasDocument, baseOptions);
      }
export function useEntryAreasLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryAreasQuery, EntryAreasQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryAreasQuery, EntryAreasQueryVariables>(EntryAreasDocument, baseOptions);
        }
export type EntryAreasQueryHookResult = ReturnType<typeof useEntryAreasQuery>;
export type EntryAreasLazyQueryHookResult = ReturnType<typeof useEntryAreasLazyQuery>;
export type EntryAreasQueryResult = ApolloReactCommon.QueryResult<EntryAreasQuery, EntryAreasQueryVariables>;
export const RemoveAreaDocument = gql`
    mutation RemoveArea($id: String!) {
  removeArea(id: $id)
}
    `;
export type RemoveAreaMutationFn = ApolloReactCommon.MutationFunction<RemoveAreaMutation, RemoveAreaMutationVariables>;

/**
 * __useRemoveAreaMutation__
 *
 * To run a mutation, you first call `useRemoveAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAreaMutation, { data, loading, error }] = useRemoveAreaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAreaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAreaMutation, RemoveAreaMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAreaMutation, RemoveAreaMutationVariables>(RemoveAreaDocument, baseOptions);
      }
export type RemoveAreaMutationHookResult = ReturnType<typeof useRemoveAreaMutation>;
export type RemoveAreaMutationResult = ApolloReactCommon.MutationResult<RemoveAreaMutation>;
export type RemoveAreaMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAreaMutation, RemoveAreaMutationVariables>;
export const UpdateAreaDocument = gql`
    mutation UpdateArea($id: String!, $name: String!, $tag: String!) {
  updateArea(id: $id, name: $name, tag: $tag)
}
    `;
export type UpdateAreaMutationFn = ApolloReactCommon.MutationFunction<UpdateAreaMutation, UpdateAreaMutationVariables>;

/**
 * __useUpdateAreaMutation__
 *
 * To run a mutation, you first call `useUpdateAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaMutation, { data, loading, error }] = useUpdateAreaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useUpdateAreaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAreaMutation, UpdateAreaMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAreaMutation, UpdateAreaMutationVariables>(UpdateAreaDocument, baseOptions);
      }
export type UpdateAreaMutationHookResult = ReturnType<typeof useUpdateAreaMutation>;
export type UpdateAreaMutationResult = ApolloReactCommon.MutationResult<UpdateAreaMutation>;
export type UpdateAreaMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAreaMutation, UpdateAreaMutationVariables>;
export const CalEntriesDocument = gql`
    query CalEntries {
  calEntries {
    id
    title
    month
    year
    days
  }
}
    `;

/**
 * __useCalEntriesQuery__
 *
 * To run a query within a React component, call `useCalEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalEntriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalEntriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalEntriesQuery, CalEntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<CalEntriesQuery, CalEntriesQueryVariables>(CalEntriesDocument, baseOptions);
      }
export function useCalEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalEntriesQuery, CalEntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalEntriesQuery, CalEntriesQueryVariables>(CalEntriesDocument, baseOptions);
        }
export type CalEntriesQueryHookResult = ReturnType<typeof useCalEntriesQuery>;
export type CalEntriesLazyQueryHookResult = ReturnType<typeof useCalEntriesLazyQuery>;
export type CalEntriesQueryResult = ApolloReactCommon.QueryResult<CalEntriesQuery, CalEntriesQueryVariables>;
export const CreateCalEntryDocument = gql`
    mutation CreateCalEntry($title: String!, $month: Int!, $days: JSON!, $year: Int!) {
  createCalEntry(title: $title, month: $month, days: $days, year: $year)
}
    `;
export type CreateCalEntryMutationFn = ApolloReactCommon.MutationFunction<CreateCalEntryMutation, CreateCalEntryMutationVariables>;

/**
 * __useCreateCalEntryMutation__
 *
 * To run a mutation, you first call `useCreateCalEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalEntryMutation, { data, loading, error }] = useCreateCalEntryMutation({
 *   variables: {
 *      title: // value for 'title'
 *      month: // value for 'month'
 *      days: // value for 'days'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useCreateCalEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCalEntryMutation, CreateCalEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCalEntryMutation, CreateCalEntryMutationVariables>(CreateCalEntryDocument, baseOptions);
      }
export type CreateCalEntryMutationHookResult = ReturnType<typeof useCreateCalEntryMutation>;
export type CreateCalEntryMutationResult = ApolloReactCommon.MutationResult<CreateCalEntryMutation>;
export type CreateCalEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCalEntryMutation, CreateCalEntryMutationVariables>;
export const EntriesDocument = gql`
    query Entries($id: String!) {
  entries(id: $id) {
    id
    title
    month
    year
    days
  }
}
    `;

/**
 * __useEntriesQuery__
 *
 * To run a query within a React component, call `useEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntriesQuery, EntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<EntriesQuery, EntriesQueryVariables>(EntriesDocument, baseOptions);
      }
export function useEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntriesQuery, EntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntriesQuery, EntriesQueryVariables>(EntriesDocument, baseOptions);
        }
export type EntriesQueryHookResult = ReturnType<typeof useEntriesQuery>;
export type EntriesLazyQueryHookResult = ReturnType<typeof useEntriesLazyQuery>;
export type EntriesQueryResult = ApolloReactCommon.QueryResult<EntriesQuery, EntriesQueryVariables>;
export const RemoveCalEntryDocument = gql`
    mutation RemoveCalEntry($id: String!) {
  removeCalEntry(id: $id)
}
    `;
export type RemoveCalEntryMutationFn = ApolloReactCommon.MutationFunction<RemoveCalEntryMutation, RemoveCalEntryMutationVariables>;

/**
 * __useRemoveCalEntryMutation__
 *
 * To run a mutation, you first call `useRemoveCalEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCalEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCalEntryMutation, { data, loading, error }] = useRemoveCalEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCalEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCalEntryMutation, RemoveCalEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCalEntryMutation, RemoveCalEntryMutationVariables>(RemoveCalEntryDocument, baseOptions);
      }
export type RemoveCalEntryMutationHookResult = ReturnType<typeof useRemoveCalEntryMutation>;
export type RemoveCalEntryMutationResult = ApolloReactCommon.MutationResult<RemoveCalEntryMutation>;
export type RemoveCalEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCalEntryMutation, RemoveCalEntryMutationVariables>;
export const RemoveCalMonthDocument = gql`
    mutation RemoveCalMonth($id: Float!) {
  removeCalMonth(id: $id)
}
    `;
export type RemoveCalMonthMutationFn = ApolloReactCommon.MutationFunction<RemoveCalMonthMutation, RemoveCalMonthMutationVariables>;

/**
 * __useRemoveCalMonthMutation__
 *
 * To run a mutation, you first call `useRemoveCalMonthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCalMonthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCalMonthMutation, { data, loading, error }] = useRemoveCalMonthMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCalMonthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCalMonthMutation, RemoveCalMonthMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCalMonthMutation, RemoveCalMonthMutationVariables>(RemoveCalMonthDocument, baseOptions);
      }
export type RemoveCalMonthMutationHookResult = ReturnType<typeof useRemoveCalMonthMutation>;
export type RemoveCalMonthMutationResult = ApolloReactCommon.MutationResult<RemoveCalMonthMutation>;
export type RemoveCalMonthMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCalMonthMutation, RemoveCalMonthMutationVariables>;
export const RemoveCalYearDocument = gql`
    mutation RemoveCalYear($id: Float!) {
  removeCalYear(id: $id)
}
    `;
export type RemoveCalYearMutationFn = ApolloReactCommon.MutationFunction<RemoveCalYearMutation, RemoveCalYearMutationVariables>;

/**
 * __useRemoveCalYearMutation__
 *
 * To run a mutation, you first call `useRemoveCalYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCalYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCalYearMutation, { data, loading, error }] = useRemoveCalYearMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCalYearMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCalYearMutation, RemoveCalYearMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCalYearMutation, RemoveCalYearMutationVariables>(RemoveCalYearDocument, baseOptions);
      }
export type RemoveCalYearMutationHookResult = ReturnType<typeof useRemoveCalYearMutation>;
export type RemoveCalYearMutationResult = ApolloReactCommon.MutationResult<RemoveCalYearMutation>;
export type RemoveCalYearMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCalYearMutation, RemoveCalYearMutationVariables>;
export const UpdateCalEntryDocument = gql`
    mutation UpdateCalEntry($id: String!, $title: String!, $month: Int!, $days: JSON!) {
  updateCalEntry(id: $id, title: $title, month: $month, days: $days)
}
    `;
export type UpdateCalEntryMutationFn = ApolloReactCommon.MutationFunction<UpdateCalEntryMutation, UpdateCalEntryMutationVariables>;

/**
 * __useUpdateCalEntryMutation__
 *
 * To run a mutation, you first call `useUpdateCalEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalEntryMutation, { data, loading, error }] = useUpdateCalEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      month: // value for 'month'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useUpdateCalEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCalEntryMutation, UpdateCalEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCalEntryMutation, UpdateCalEntryMutationVariables>(UpdateCalEntryDocument, baseOptions);
      }
export type UpdateCalEntryMutationHookResult = ReturnType<typeof useUpdateCalEntryMutation>;
export type UpdateCalEntryMutationResult = ApolloReactCommon.MutationResult<UpdateCalEntryMutation>;
export type UpdateCalEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCalEntryMutation, UpdateCalEntryMutationVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($event: InputEvent!, $year: Int!) {
  createEvent(event: $event, year: $year)
}
    `;
export type CreateEventMutationFn = ApolloReactCommon.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, baseOptions);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = ApolloReactCommon.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const CreateEventsDocument = gql`
    mutation CreateEvents($events: [InputEvent!]!, $year: String!) {
  createEvents(events: $events, year: $year)
}
    `;
export type CreateEventsMutationFn = ApolloReactCommon.MutationFunction<CreateEventsMutation, CreateEventsMutationVariables>;

/**
 * __useCreateEventsMutation__
 *
 * To run a mutation, you first call `useCreateEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventsMutation, { data, loading, error }] = useCreateEventsMutation({
 *   variables: {
 *      events: // value for 'events'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useCreateEventsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventsMutation, CreateEventsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEventsMutation, CreateEventsMutationVariables>(CreateEventsDocument, baseOptions);
      }
export type CreateEventsMutationHookResult = ReturnType<typeof useCreateEventsMutation>;
export type CreateEventsMutationResult = ApolloReactCommon.MutationResult<CreateEventsMutation>;
export type CreateEventsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEventsMutation, CreateEventsMutationVariables>;
export const EntryEventsDocument = gql`
    query EntryEvents($id: String!) {
  entryEvents(id: $id) {
    id
    name
    description
    alarmDescription
    day
    month
    startTime
    endTime
    year {
      id
      active
      value
    }
    type {
      id
    }
    areas {
      id
      name
    }
  }
}
    `;

/**
 * __useEntryEventsQuery__
 *
 * To run a query within a React component, call `useEntryEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntryEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntryEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntryEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryEventsQuery, EntryEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryEventsQuery, EntryEventsQueryVariables>(EntryEventsDocument, baseOptions);
      }
export function useEntryEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryEventsQuery, EntryEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryEventsQuery, EntryEventsQueryVariables>(EntryEventsDocument, baseOptions);
        }
export type EntryEventsQueryHookResult = ReturnType<typeof useEntryEventsQuery>;
export type EntryEventsLazyQueryHookResult = ReturnType<typeof useEntryEventsLazyQuery>;
export type EntryEventsQueryResult = ApolloReactCommon.QueryResult<EntryEventsQuery, EntryEventsQueryVariables>;
export const EventsDocument = gql`
    query Events {
  events {
    id
    name
    description
    alarmDescription
    day
    month
    startTime
    endTime
    year {
      id
      value
    }
    type {
      id
    }
    areas {
      id
      name
    }
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        return ApolloReactHooks.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, baseOptions);
      }
export function useEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, baseOptions);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = ApolloReactCommon.QueryResult<EventsQuery, EventsQueryVariables>;
export const RemoveEventsByYearDocument = gql`
    mutation RemoveEventsByYear($year: String!) {
  removeEventsByYear(year: $year)
}
    `;
export type RemoveEventsByYearMutationFn = ApolloReactCommon.MutationFunction<RemoveEventsByYearMutation, RemoveEventsByYearMutationVariables>;

/**
 * __useRemoveEventsByYearMutation__
 *
 * To run a mutation, you first call `useRemoveEventsByYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventsByYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventsByYearMutation, { data, loading, error }] = useRemoveEventsByYearMutation({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useRemoveEventsByYearMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveEventsByYearMutation, RemoveEventsByYearMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveEventsByYearMutation, RemoveEventsByYearMutationVariables>(RemoveEventsByYearDocument, baseOptions);
      }
export type RemoveEventsByYearMutationHookResult = ReturnType<typeof useRemoveEventsByYearMutation>;
export type RemoveEventsByYearMutationResult = ApolloReactCommon.MutationResult<RemoveEventsByYearMutation>;
export type RemoveEventsByYearMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveEventsByYearMutation, RemoveEventsByYearMutationVariables>;
export const RemoveEventDocument = gql`
    mutation RemoveEvent($id: String!) {
  removeEvent(id: $id)
}
    `;
export type RemoveEventMutationFn = ApolloReactCommon.MutationFunction<RemoveEventMutation, RemoveEventMutationVariables>;

/**
 * __useRemoveEventMutation__
 *
 * To run a mutation, you first call `useRemoveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventMutation, { data, loading, error }] = useRemoveEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveEventMutation, RemoveEventMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveEventMutation, RemoveEventMutationVariables>(RemoveEventDocument, baseOptions);
      }
export type RemoveEventMutationHookResult = ReturnType<typeof useRemoveEventMutation>;
export type RemoveEventMutationResult = ApolloReactCommon.MutationResult<RemoveEventMutation>;
export type RemoveEventMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveEventMutation, RemoveEventMutationVariables>;
export const RemoveEventsByMonthDocument = gql`
    mutation RemoveEventsByMonth($month: Int!, $year: String!) {
  removeEventsByMonth(month: $month, year: $year)
}
    `;
export type RemoveEventsByMonthMutationFn = ApolloReactCommon.MutationFunction<RemoveEventsByMonthMutation, RemoveEventsByMonthMutationVariables>;

/**
 * __useRemoveEventsByMonthMutation__
 *
 * To run a mutation, you first call `useRemoveEventsByMonthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventsByMonthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventsByMonthMutation, { data, loading, error }] = useRemoveEventsByMonthMutation({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useRemoveEventsByMonthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveEventsByMonthMutation, RemoveEventsByMonthMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveEventsByMonthMutation, RemoveEventsByMonthMutationVariables>(RemoveEventsByMonthDocument, baseOptions);
      }
export type RemoveEventsByMonthMutationHookResult = ReturnType<typeof useRemoveEventsByMonthMutation>;
export type RemoveEventsByMonthMutationResult = ApolloReactCommon.MutationResult<RemoveEventsByMonthMutation>;
export type RemoveEventsByMonthMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveEventsByMonthMutation, RemoveEventsByMonthMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($event: InputEvent!, $id: String!) {
  updateEvent(event: $event, id: $id)
}
    `;
export type UpdateEventMutationFn = ApolloReactCommon.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, baseOptions);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = ApolloReactCommon.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const UpdateEventsDocument = gql`
    mutation UpdateEvents($events: [InputEvent!]!, $year: String!) {
  updateEvents(events: $events, year: $year)
}
    `;
export type UpdateEventsMutationFn = ApolloReactCommon.MutationFunction<UpdateEventsMutation, UpdateEventsMutationVariables>;

/**
 * __useUpdateEventsMutation__
 *
 * To run a mutation, you first call `useUpdateEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventsMutation, { data, loading, error }] = useUpdateEventsMutation({
 *   variables: {
 *      events: // value for 'events'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useUpdateEventsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventsMutation, UpdateEventsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEventsMutation, UpdateEventsMutationVariables>(UpdateEventsDocument, baseOptions);
      }
export type UpdateEventsMutationHookResult = ReturnType<typeof useUpdateEventsMutation>;
export type UpdateEventsMutationResult = ApolloReactCommon.MutationResult<UpdateEventsMutation>;
export type UpdateEventsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEventsMutation, UpdateEventsMutationVariables>;
export const CreatePatternDocument = gql`
    mutation CreatePattern($year: String!, $number: Int!) {
  createPattern(year: $year, number: $number)
}
    `;
export type CreatePatternMutationFn = ApolloReactCommon.MutationFunction<CreatePatternMutation, CreatePatternMutationVariables>;

/**
 * __useCreatePatternMutation__
 *
 * To run a mutation, you first call `useCreatePatternMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatternMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatternMutation, { data, loading, error }] = useCreatePatternMutation({
 *   variables: {
 *      year: // value for 'year'
 *      number: // value for 'number'
 *   },
 * });
 */
export function useCreatePatternMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePatternMutation, CreatePatternMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePatternMutation, CreatePatternMutationVariables>(CreatePatternDocument, baseOptions);
      }
export type CreatePatternMutationHookResult = ReturnType<typeof useCreatePatternMutation>;
export type CreatePatternMutationResult = ApolloReactCommon.MutationResult<CreatePatternMutation>;
export type CreatePatternMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePatternMutation, CreatePatternMutationVariables>;
export const PatternsDocument = gql`
    query Patterns {
  patterns {
    id
    year
    number
  }
}
    `;

/**
 * __usePatternsQuery__
 *
 * To run a query within a React component, call `usePatternsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatternsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatternsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatternsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatternsQuery, PatternsQueryVariables>) {
        return ApolloReactHooks.useQuery<PatternsQuery, PatternsQueryVariables>(PatternsDocument, baseOptions);
      }
export function usePatternsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatternsQuery, PatternsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatternsQuery, PatternsQueryVariables>(PatternsDocument, baseOptions);
        }
export type PatternsQueryHookResult = ReturnType<typeof usePatternsQuery>;
export type PatternsLazyQueryHookResult = ReturnType<typeof usePatternsLazyQuery>;
export type PatternsQueryResult = ApolloReactCommon.QueryResult<PatternsQuery, PatternsQueryVariables>;
export const RemovePatternDocument = gql`
    mutation RemovePattern($id: String!) {
  removePattern(id: $id)
}
    `;
export type RemovePatternMutationFn = ApolloReactCommon.MutationFunction<RemovePatternMutation, RemovePatternMutationVariables>;

/**
 * __useRemovePatternMutation__
 *
 * To run a mutation, you first call `useRemovePatternMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePatternMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePatternMutation, { data, loading, error }] = useRemovePatternMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePatternMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePatternMutation, RemovePatternMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePatternMutation, RemovePatternMutationVariables>(RemovePatternDocument, baseOptions);
      }
export type RemovePatternMutationHookResult = ReturnType<typeof useRemovePatternMutation>;
export type RemovePatternMutationResult = ApolloReactCommon.MutationResult<RemovePatternMutation>;
export type RemovePatternMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePatternMutation, RemovePatternMutationVariables>;
export const UpdatePatternDocument = gql`
    mutation UpdatePattern($id: String!, $year: String!, $number: Int!) {
  updatePattern(id: $id, year: $year, number: $number)
}
    `;
export type UpdatePatternMutationFn = ApolloReactCommon.MutationFunction<UpdatePatternMutation, UpdatePatternMutationVariables>;

/**
 * __useUpdatePatternMutation__
 *
 * To run a mutation, you first call `useUpdatePatternMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatternMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatternMutation, { data, loading, error }] = useUpdatePatternMutation({
 *   variables: {
 *      id: // value for 'id'
 *      year: // value for 'year'
 *      number: // value for 'number'
 *   },
 * });
 */
export function useUpdatePatternMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePatternMutation, UpdatePatternMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePatternMutation, UpdatePatternMutationVariables>(UpdatePatternDocument, baseOptions);
      }
export type UpdatePatternMutationHookResult = ReturnType<typeof useUpdatePatternMutation>;
export type UpdatePatternMutationResult = ApolloReactCommon.MutationResult<UpdatePatternMutation>;
export type UpdatePatternMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePatternMutation, UpdatePatternMutationVariables>;
export const SettingsDocument = gql`
    query Settings {
  settings {
    id
    name
    value
  }
}
    `;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, baseOptions);
      }
export function useSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, baseOptions);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = ApolloReactCommon.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const UpdateSettingDocument = gql`
    mutation UpdateSetting($id: Float!, $value: String!) {
  updateSetting(id: $id, value: $value)
}
    `;
export type UpdateSettingMutationFn = ApolloReactCommon.MutationFunction<UpdateSettingMutation, UpdateSettingMutationVariables>;

/**
 * __useUpdateSettingMutation__
 *
 * To run a mutation, you first call `useUpdateSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingMutation, { data, loading, error }] = useUpdateSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateSettingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSettingMutation, UpdateSettingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSettingMutation, UpdateSettingMutationVariables>(UpdateSettingDocument, baseOptions);
      }
export type UpdateSettingMutationHookResult = ReturnType<typeof useUpdateSettingMutation>;
export type UpdateSettingMutationResult = ApolloReactCommon.MutationResult<UpdateSettingMutation>;
export type UpdateSettingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSettingMutation, UpdateSettingMutationVariables>;
export const CreateStreetDocument = gql`
    mutation CreateStreet($name: String!, $areaId: String!) {
  createStreet(name: $name, areaId: $areaId)
}
    `;
export type CreateStreetMutationFn = ApolloReactCommon.MutationFunction<CreateStreetMutation, CreateStreetMutationVariables>;

/**
 * __useCreateStreetMutation__
 *
 * To run a mutation, you first call `useCreateStreetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStreetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStreetMutation, { data, loading, error }] = useCreateStreetMutation({
 *   variables: {
 *      name: // value for 'name'
 *      areaId: // value for 'areaId'
 *   },
 * });
 */
export function useCreateStreetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStreetMutation, CreateStreetMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStreetMutation, CreateStreetMutationVariables>(CreateStreetDocument, baseOptions);
      }
export type CreateStreetMutationHookResult = ReturnType<typeof useCreateStreetMutation>;
export type CreateStreetMutationResult = ApolloReactCommon.MutationResult<CreateStreetMutation>;
export type CreateStreetMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStreetMutation, CreateStreetMutationVariables>;
export const CreateStreetsDocument = gql`
    mutation CreateStreets($streets: [InputStreet!]!) {
  createStreets(streets: $streets)
}
    `;
export type CreateStreetsMutationFn = ApolloReactCommon.MutationFunction<CreateStreetsMutation, CreateStreetsMutationVariables>;

/**
 * __useCreateStreetsMutation__
 *
 * To run a mutation, you first call `useCreateStreetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStreetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStreetsMutation, { data, loading, error }] = useCreateStreetsMutation({
 *   variables: {
 *      streets: // value for 'streets'
 *   },
 * });
 */
export function useCreateStreetsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStreetsMutation, CreateStreetsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStreetsMutation, CreateStreetsMutationVariables>(CreateStreetsDocument, baseOptions);
      }
export type CreateStreetsMutationHookResult = ReturnType<typeof useCreateStreetsMutation>;
export type CreateStreetsMutationResult = ApolloReactCommon.MutationResult<CreateStreetsMutation>;
export type CreateStreetsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStreetsMutation, CreateStreetsMutationVariables>;
export const EntryStreetsDocument = gql`
    query EntryStreets($id: String!) {
  entryStreets(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useEntryStreetsQuery__
 *
 * To run a query within a React component, call `useEntryStreetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntryStreetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntryStreetsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntryStreetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryStreetsQuery, EntryStreetsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryStreetsQuery, EntryStreetsQueryVariables>(EntryStreetsDocument, baseOptions);
      }
export function useEntryStreetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryStreetsQuery, EntryStreetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryStreetsQuery, EntryStreetsQueryVariables>(EntryStreetsDocument, baseOptions);
        }
export type EntryStreetsQueryHookResult = ReturnType<typeof useEntryStreetsQuery>;
export type EntryStreetsLazyQueryHookResult = ReturnType<typeof useEntryStreetsLazyQuery>;
export type EntryStreetsQueryResult = ApolloReactCommon.QueryResult<EntryStreetsQuery, EntryStreetsQueryVariables>;
export const RemoveStreetDocument = gql`
    mutation RemoveStreet($id: String!) {
  removeStreet(id: $id)
}
    `;
export type RemoveStreetMutationFn = ApolloReactCommon.MutationFunction<RemoveStreetMutation, RemoveStreetMutationVariables>;

/**
 * __useRemoveStreetMutation__
 *
 * To run a mutation, you first call `useRemoveStreetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStreetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStreetMutation, { data, loading, error }] = useRemoveStreetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveStreetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveStreetMutation, RemoveStreetMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveStreetMutation, RemoveStreetMutationVariables>(RemoveStreetDocument, baseOptions);
      }
export type RemoveStreetMutationHookResult = ReturnType<typeof useRemoveStreetMutation>;
export type RemoveStreetMutationResult = ApolloReactCommon.MutationResult<RemoveStreetMutation>;
export type RemoveStreetMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveStreetMutation, RemoveStreetMutationVariables>;
export const StreetsDocument = gql`
    query Streets {
  streets {
    id
    name
    area {
      id
      name
    }
  }
}
    `;

/**
 * __useStreetsQuery__
 *
 * To run a query within a React component, call `useStreetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStreetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StreetsQuery, StreetsQueryVariables>) {
        return ApolloReactHooks.useQuery<StreetsQuery, StreetsQueryVariables>(StreetsDocument, baseOptions);
      }
export function useStreetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StreetsQuery, StreetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StreetsQuery, StreetsQueryVariables>(StreetsDocument, baseOptions);
        }
export type StreetsQueryHookResult = ReturnType<typeof useStreetsQuery>;
export type StreetsLazyQueryHookResult = ReturnType<typeof useStreetsLazyQuery>;
export type StreetsQueryResult = ApolloReactCommon.QueryResult<StreetsQuery, StreetsQueryVariables>;
export const UpdateStreetDocument = gql`
    mutation UpdateStreet($id: String!, $name: String!, $areaId: String!) {
  updateStreet(id: $id, name: $name, areaId: $areaId)
}
    `;
export type UpdateStreetMutationFn = ApolloReactCommon.MutationFunction<UpdateStreetMutation, UpdateStreetMutationVariables>;

/**
 * __useUpdateStreetMutation__
 *
 * To run a mutation, you first call `useUpdateStreetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStreetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStreetMutation, { data, loading, error }] = useUpdateStreetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      areaId: // value for 'areaId'
 *   },
 * });
 */
export function useUpdateStreetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStreetMutation, UpdateStreetMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStreetMutation, UpdateStreetMutationVariables>(UpdateStreetDocument, baseOptions);
      }
export type UpdateStreetMutationHookResult = ReturnType<typeof useUpdateStreetMutation>;
export type UpdateStreetMutationResult = ApolloReactCommon.MutationResult<UpdateStreetMutation>;
export type UpdateStreetMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStreetMutation, UpdateStreetMutationVariables>;
export const CreateTypeDocument = gql`
    mutation CreateType($name: String!, $description: String!, $alarmDescription: String!, $startTime: String, $endTime: String, $tag: String!, $color: String!) {
  createType(name: $name, description: $description, alarmDescription: $alarmDescription, startTime: $startTime, endTime: $endTime, tag: $tag, color: $color)
}
    `;
export type CreateTypeMutationFn = ApolloReactCommon.MutationFunction<CreateTypeMutation, CreateTypeMutationVariables>;

/**
 * __useCreateTypeMutation__
 *
 * To run a mutation, you first call `useCreateTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTypeMutation, { data, loading, error }] = useCreateTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      alarmDescription: // value for 'alarmDescription'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      tag: // value for 'tag'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useCreateTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTypeMutation, CreateTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTypeMutation, CreateTypeMutationVariables>(CreateTypeDocument, baseOptions);
      }
export type CreateTypeMutationHookResult = ReturnType<typeof useCreateTypeMutation>;
export type CreateTypeMutationResult = ApolloReactCommon.MutationResult<CreateTypeMutation>;
export type CreateTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTypeMutation, CreateTypeMutationVariables>;
export const EntryTypesDocument = gql`
    query EntryTypes($id: String!) {
  entryTypes(id: $id) {
    id
    name
    description
    alarmDescription
    startTime
    endTime
    tag
    color
  }
}
    `;

/**
 * __useEntryTypesQuery__
 *
 * To run a query within a React component, call `useEntryTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntryTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntryTypesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntryTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryTypesQuery, EntryTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryTypesQuery, EntryTypesQueryVariables>(EntryTypesDocument, baseOptions);
      }
export function useEntryTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryTypesQuery, EntryTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryTypesQuery, EntryTypesQueryVariables>(EntryTypesDocument, baseOptions);
        }
export type EntryTypesQueryHookResult = ReturnType<typeof useEntryTypesQuery>;
export type EntryTypesLazyQueryHookResult = ReturnType<typeof useEntryTypesLazyQuery>;
export type EntryTypesQueryResult = ApolloReactCommon.QueryResult<EntryTypesQuery, EntryTypesQueryVariables>;
export const RemoveTypeDocument = gql`
    mutation RemoveType($id: String!) {
  removeType(id: $id)
}
    `;
export type RemoveTypeMutationFn = ApolloReactCommon.MutationFunction<RemoveTypeMutation, RemoveTypeMutationVariables>;

/**
 * __useRemoveTypeMutation__
 *
 * To run a mutation, you first call `useRemoveTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTypeMutation, { data, loading, error }] = useRemoveTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTypeMutation, RemoveTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTypeMutation, RemoveTypeMutationVariables>(RemoveTypeDocument, baseOptions);
      }
export type RemoveTypeMutationHookResult = ReturnType<typeof useRemoveTypeMutation>;
export type RemoveTypeMutationResult = ApolloReactCommon.MutationResult<RemoveTypeMutation>;
export type RemoveTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTypeMutation, RemoveTypeMutationVariables>;
export const TypesDocument = gql`
    query Types {
  types {
    id
    name
    description
    alarmDescription
    startTime
    endTime
    tag
    color
  }
}
    `;

/**
 * __useTypesQuery__
 *
 * To run a query within a React component, call `useTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TypesQuery, TypesQueryVariables>) {
        return ApolloReactHooks.useQuery<TypesQuery, TypesQueryVariables>(TypesDocument, baseOptions);
      }
export function useTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TypesQuery, TypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TypesQuery, TypesQueryVariables>(TypesDocument, baseOptions);
        }
export type TypesQueryHookResult = ReturnType<typeof useTypesQuery>;
export type TypesLazyQueryHookResult = ReturnType<typeof useTypesLazyQuery>;
export type TypesQueryResult = ApolloReactCommon.QueryResult<TypesQuery, TypesQueryVariables>;
export const UpdateTypeDocument = gql`
    mutation UpdateType($id: String!, $name: String!, $description: String!, $alarmDescription: String!, $startTime: String, $endTime: String, $tag: String!, $color: String!) {
  updateType(id: $id, name: $name, description: $description, alarmDescription: $alarmDescription, startTime: $startTime, endTime: $endTime, tag: $tag, color: $color)
}
    `;
export type UpdateTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateTypeMutation, UpdateTypeMutationVariables>;

/**
 * __useUpdateTypeMutation__
 *
 * To run a mutation, you first call `useUpdateTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTypeMutation, { data, loading, error }] = useUpdateTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      alarmDescription: // value for 'alarmDescription'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      tag: // value for 'tag'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useUpdateTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTypeMutation, UpdateTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTypeMutation, UpdateTypeMutationVariables>(UpdateTypeDocument, baseOptions);
      }
export type UpdateTypeMutationHookResult = ReturnType<typeof useUpdateTypeMutation>;
export type UpdateTypeMutationResult = ApolloReactCommon.MutationResult<UpdateTypeMutation>;
export type UpdateTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTypeMutation, UpdateTypeMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    accessToken
    refreshToken
    user {
      id
      name
      lastname
      email
      verified
      dsgvo
      role
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    name
    lastname
    email
    dsgvo
    verified
    role
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const RegisterDocument = gql`
    mutation Register($name: String!, $lastname: String!, $email: String!, $dsgvo: Boolean!, $password: String!, $role: String!) {
  register(name: $name, lastname: $lastname, email: $email, dsgvo: $dsgvo, password: $password, role: $role)
}
    `;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      name: // value for 'name'
 *      lastname: // value for 'lastname'
 *      email: // value for 'email'
 *      dsgvo: // value for 'dsgvo'
 *      password: // value for 'password'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: String!) {
  removeUser(id: $id)
}
    `;
export type RemoveUserMutationFn = ApolloReactCommon.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, baseOptions);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = ApolloReactCommon.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const RevokeTokenDocument = gql`
    mutation RevokeToken($userId: String!) {
  revokeRefreshTokensForUser(userId: $userId)
}
    `;
export type RevokeTokenMutationFn = ApolloReactCommon.MutationFunction<RevokeTokenMutation, RevokeTokenMutationVariables>;

/**
 * __useRevokeTokenMutation__
 *
 * To run a mutation, you first call `useRevokeTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeTokenMutation, { data, loading, error }] = useRevokeTokenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRevokeTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeTokenMutation, RevokeTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeTokenMutation, RevokeTokenMutationVariables>(RevokeTokenDocument, baseOptions);
      }
export type RevokeTokenMutationHookResult = ReturnType<typeof useRevokeTokenMutation>;
export type RevokeTokenMutationResult = ApolloReactCommon.MutationResult<RevokeTokenMutation>;
export type RevokeTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeTokenMutation, RevokeTokenMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($id: String!, $role: String!) {
  updateRole(id: $id, role: $role)
}
    `;
export type UpdateRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, baseOptions);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = ApolloReactCommon.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $name: String!, $lastname: String!, $email: String!, $dsgvo: Boolean!) {
  updateUser(id: $id, name: $name, lastname: $lastname, email: $email, dsgvo: $dsgvo)
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      lastname: // value for 'lastname'
 *      email: // value for 'email'
 *      dsgvo: // value for 'dsgvo'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateVerifyDocument = gql`
    mutation UpdateVerify($id: String!, $verified: Boolean!) {
  updateVerify(id: $id, verified: $verified)
}
    `;
export type UpdateVerifyMutationFn = ApolloReactCommon.MutationFunction<UpdateVerifyMutation, UpdateVerifyMutationVariables>;

/**
 * __useUpdateVerifyMutation__
 *
 * To run a mutation, you first call `useUpdateVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVerifyMutation, { data, loading, error }] = useUpdateVerifyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useUpdateVerifyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVerifyMutation, UpdateVerifyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateVerifyMutation, UpdateVerifyMutationVariables>(UpdateVerifyDocument, baseOptions);
      }
export type UpdateVerifyMutationHookResult = ReturnType<typeof useUpdateVerifyMutation>;
export type UpdateVerifyMutationResult = ApolloReactCommon.MutationResult<UpdateVerifyMutation>;
export type UpdateVerifyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVerifyMutation, UpdateVerifyMutationVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    id
    name
    lastname
    email
    dsgvo
    verified
    role
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const VerifyUserDocument = gql`
    query VerifyUser($token: String!) {
  verifyUser(token: $token)
}
    `;

/**
 * __useVerifyUserQuery__
 *
 * To run a query within a React component, call `useVerifyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyUserQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifyUserQuery, VerifyUserQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifyUserQuery, VerifyUserQueryVariables>(VerifyUserDocument, baseOptions);
      }
export function useVerifyUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifyUserQuery, VerifyUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifyUserQuery, VerifyUserQueryVariables>(VerifyUserDocument, baseOptions);
        }
export type VerifyUserQueryHookResult = ReturnType<typeof useVerifyUserQuery>;
export type VerifyUserLazyQueryHookResult = ReturnType<typeof useVerifyUserLazyQuery>;
export type VerifyUserQueryResult = ApolloReactCommon.QueryResult<VerifyUserQuery, VerifyUserQueryVariables>;
export const ToggleYearDocument = gql`
    mutation ToggleYear($id: String!, $active: Boolean!) {
  toggleYear(id: $id, active: $active)
}
    `;
export type ToggleYearMutationFn = ApolloReactCommon.MutationFunction<ToggleYearMutation, ToggleYearMutationVariables>;

/**
 * __useToggleYearMutation__
 *
 * To run a mutation, you first call `useToggleYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleYearMutation, { data, loading, error }] = useToggleYearMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useToggleYearMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleYearMutation, ToggleYearMutationVariables>) {
        return ApolloReactHooks.useMutation<ToggleYearMutation, ToggleYearMutationVariables>(ToggleYearDocument, baseOptions);
      }
export type ToggleYearMutationHookResult = ReturnType<typeof useToggleYearMutation>;
export type ToggleYearMutationResult = ApolloReactCommon.MutationResult<ToggleYearMutation>;
export type ToggleYearMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleYearMutation, ToggleYearMutationVariables>;
export const CreateYearDocument = gql`
    mutation CreateYear($value: Float!) {
  createYear(value: $value)
}
    `;
export type CreateYearMutationFn = ApolloReactCommon.MutationFunction<CreateYearMutation, CreateYearMutationVariables>;

/**
 * __useCreateYearMutation__
 *
 * To run a mutation, you first call `useCreateYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createYearMutation, { data, loading, error }] = useCreateYearMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateYearMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateYearMutation, CreateYearMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateYearMutation, CreateYearMutationVariables>(CreateYearDocument, baseOptions);
      }
export type CreateYearMutationHookResult = ReturnType<typeof useCreateYearMutation>;
export type CreateYearMutationResult = ApolloReactCommon.MutationResult<CreateYearMutation>;
export type CreateYearMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateYearMutation, CreateYearMutationVariables>;
export const YearsDocument = gql`
    query Years {
  years {
    id
    active
    value
  }
}
    `;

/**
 * __useYearsQuery__
 *
 * To run a query within a React component, call `useYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYearsQuery({
 *   variables: {
 *   },
 * });
 */
export function useYearsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<YearsQuery, YearsQueryVariables>) {
        return ApolloReactHooks.useQuery<YearsQuery, YearsQueryVariables>(YearsDocument, baseOptions);
      }
export function useYearsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<YearsQuery, YearsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<YearsQuery, YearsQueryVariables>(YearsDocument, baseOptions);
        }
export type YearsQueryHookResult = ReturnType<typeof useYearsQuery>;
export type YearsLazyQueryHookResult = ReturnType<typeof useYearsLazyQuery>;
export type YearsQueryResult = ApolloReactCommon.QueryResult<YearsQuery, YearsQueryVariables>;