let accessToken = "";

export const setAccessToken = (s: string) => {
  accessToken = s;
};

export const getAccessToken = () => {
  return accessToken;
};

export const setRefreshToken = (token) => {
  return localStorage.setItem('jid', token);
}
