import React, { useState } from 'react';
import { Button, Card } from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import styles from './types.module.scss';
import { TypesTable } from './TypesTable';
import { CreateType } from './CreateType';
import { useTypesQuery } from '../../../generated/graphql';

export const Types: React.FC = () => {
  const [open, set] = useState(false);
  const { data, refetch, loading} = useTypesQuery()
  const [type, setType] = useState()

  const handleEdit = (type) => {
    setType(type);
    set(true);
  }

  const reset = () => {
    // setType(null);
    // set(false);
    refetch();
  }

  return (
    <Card
      title={"Abfuhrtypen"}
      headStyle={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#003363"
      }}
      extra={
        <Button shape={"circle"} className={styles.button} type="primary" onClick={() => set(c => !c)}>
          {!open ? <PlusOutlined translate={'no'} /> : <MinusOutlined translate={'no'} />}
        </Button>
      }
      className={styles.content}
      bordered={false}
    >
      {open && <CreateType type={type} reset={reset} />}
      {data && <TypesTable types={data.types} loading={loading} refetch={refetch} edit={handleEdit} />}
    </Card>
  );
}