import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import Loader from '../../../Components/Loader';
import styles from './styles.module.scss';
import { useUsersQuery } from '../../../generated/graphql';
import {
  SyncOutlined,
} from '@ant-design/icons';

const { Title } = Typography;

export const Dashboard: React.FC = () => {
  const { data: userData, refetch: refetchUsers, loading: loadingUsers } = useUsersQuery({ fetchPolicy: "network-only" });

  return (
    <Row gutter={[32, 32]} className={styles.row}>
      <Col xl={8} sm={12} xs={32} className={styles.col}>
        <Card
          title={"Users"}
          headStyle={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            color: "#003363"
          }}
          extra={<SyncOutlined translate={"no"} spin={loadingUsers} onClick={() => refetchUsers()} className={styles.link} />}
          className={styles.content}
          bordered={false}
        >
          <div className={styles.center}>
            {loadingUsers && <Loader />}
            {!loadingUsers && userData && (
              <Title className={styles.headline}>
                {userData && userData.users.length}
              </Title>
            )}
          </div>
        </Card>
      </Col>

      <Col xl={8} sm={12} xs={32} className={styles.col}>
        <Card
          title={"Paid Users"}
          headStyle={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            color: "#003363"
          }}
          extra={<SyncOutlined translate={"no"} spin={loadingUsers} onClick={() => refetchUsers()} className={styles.link} />}
          className={styles.content}
          bordered={false}
        >
          <div className={styles.center}>
            {loadingUsers && <Loader />}
            {!loadingUsers && userData && (
              <Title className={styles.headline}>
                {userData && userData.users.filter(u => u.role === 'PaidCustomer').length}
              </Title>
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
}
