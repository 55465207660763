import React, { useContext, useEffect, useState } from 'react';
import { Button, notification, Popconfirm, Select, Tooltip } from 'antd';
import {
  DeleteOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import moment, { Moment } from 'moment';
import styles from './calendar.module.scss';
import { Type, Area, Year } from '../../../Utils/csv';
import { Download } from './Download';
import { Event } from '../../../Utils/cal';
import { useRemoveEventsByMonthMutation, useRemoveEventsByYearMutation, useToggleYearMutation, useYearsQuery } from '../../../generated/graphql';
import { DataContext } from '../../../Context/DataContext';
import { FaIcon } from '../../../Components/FaIcon';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface HeaderProps {
  value: Moment
  years: number[]
  changeHandler: Function
  actualDate: Moment | null
  actualDay: number
  lang: string
  setLang: Function
  types: Type[]
  areas: Area[]
  actualType: string[]
  actualArea: string
  setType: Function
  setArea: Function
  events: Event[]
  reset: Function
}

export const CalendarHeader: React.FC<HeaderProps> = ({
  value,
  years,
  changeHandler,
  actualDate,
  actualDay,
  setLang,
  lang,

  types,
  areas,
  actualArea,
  actualType,
  setType,
  setArea,

  events,
  reset
}) => {
  const { me } = useContext(DataContext);
  const { data: yearsData } = useYearsQuery({ fetchPolicy: "network-only" })
  const [toggleYear] = useToggleYearMutation({ fetchPolicy: "no-cache" });
  const [actualMonth, setActualMonth] = useState<number>();
  const [actualYear, setActualYear] = useState<number>();
  const [currentYear, setCurrentYear] = useState<Year>();
  const [removeMonth] = useRemoveEventsByMonthMutation();
  const [removeYear] = useRemoveEventsByYearMutation();

  const months: number[] = Array.apply(0, Array(12)).map((_,i) => i);

  const handleMonthChange = (selected: string) => {
    const sm = moment();
    sm.set({'year': actualYear, 'month': Number(selected), 'date': actualDay});
    setActualMonth(Number(sm.format('MM')));
    changeHandler(sm)
  }

  const handleYearChange = (selected: number) => {
    const sm = moment();
    sm.set({'year': selected, 'month': actualMonth - 1, 'date': actualDay});
    setActualYear(Number(sm.format('YYYY')));
    changeHandler(sm);
  }

  const handleYearToggle = async () => {
    const toggle = await toggleYear({
      variables: {
        id: currentYear.id,
        active: !currentYear.active
      }
    });
    if (toggle.data.toggleYear) {
      notification.success({
        message: `Jahr wurde ${!currentYear.active ? 'aktiviert' : 'deaktiviert'}`
      });
      reset();
    } else {
      notification.error({
        message: 'Es ist ein Fehler aufgetreten'
      });
    }
  }

  const handleType = (value: string[]) => {
    setType(value)
  }

  const handleArea = (value: string) => {
    setArea(value)
  }

  const handleMonthRemove = async () => {
    const month = actualMonth - 1;
    const removed = await removeMonth({
      variables: {
        month,
        year: yearsData.years.find(y => y.value === actualYear).id
      }
    });

    if (removed.data.removeEventsByMonth) {
      notification.success({
        message: `Monat ${moment().month(month).format('MMMM')} wurde entfernt.`
      });
      reset();
    } else {
      notification.error({
        message: `Monat ${moment().month(month).format('MMMM')} konnte nicht entfernt werden.`
      });
    }
  }

  const handleYearRemove = async () => {
    const removed = await removeYear({
      variables: {
        year: yearsData.years.find(y => y.value === actualYear).id
      }
    });

    if (removed.data.removeEventsByYear) {
      notification.success({
        message: `Jahr ${actualYear} wurde entfernt.`
      });
      reset();
    } else {
      notification.error({
        message: `Jahr ${actualYear} konnte nicht entfernt werden.`
      });
    }
  }

  useEffect(() => {
    if (actualDate !== null) {
      setActualMonth(Number(actualDate.format('MM')))
      setActualYear(Number(actualDate.format('YYYY')))
    }
    if (actualDate === null && value !== null) {
      setActualMonth(Number(value.format('MM')))
      setActualYear(Number(value.format('YYYY')))
    }
    if (years && years.length === 1) {
      setActualYear(years[0])
    }
  }, [actualDate, value, years]);

  useEffect(() => {
    if (yearsData) {
      const y: Year = yearsData.years.find(y => y.value === parseInt(actualDate.format('YYYY')));
      setCurrentYear(y)
    }
  }, [actualDate, yearsData])

  return (!!actualDate && months.length > 0 ? (
    <div className={styles.header}>
      {months && months.length > 0 && (
        <Select
          value={String(actualMonth - 1)}
          onChange={handleMonthChange}
          className={[styles.select, styles.month].join(" ")}
          showSearch
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
        >
          {months.map((month: number) => (
            <Select.Option key={month} value={String(month)}>{moment().month(month).format('MMMM')}</Select.Option>
          ))}
        </Select>
      )}

      <Select
        value={actualYear}
        onChange={handleYearChange}
        className={[styles.select, styles.year].join(" ")}
      >
        {years.map((year) => (
          <Select.Option key={year} value={year}>{moment().year(year).format('YYYY')}</Select.Option>
        ))}
      </Select>
      <div className={styles.toggleYear}>
        {currentYear && (
          <Tooltip title={`Jahr ${currentYear.active ? 'deaktivieren' : 'aktivieren'}`}>
            <Button type={'link'} onClick={handleYearToggle}>
              {currentYear.active ? <FaIcon icon={faEye} /> : <FaIcon icon={faEyeSlash} />}
            </Button>
          </Tooltip>
        )}
      </div>
      <Select
        value={lang}
        onChange={(value) => setLang(value)}
        className={[styles.select, styles.lang].join(" ")}
      >
        <Select.Option value={'de'}>{lang === 'de' ? 'Deutsch' : 'German'}</Select.Option>
        <Select.Option value={'en'}>{lang === 'de' ? 'Englisch' : 'English'}</Select.Option>
      </Select>

      <Tooltip title={"Ausgewählten Monat entfernen"}>
        <Popconfirm
          title="Wollen Sie diesen Monat wirklich entfernen?"
          style={{ color: 'red' }}
          onConfirm={handleMonthRemove}
          placement="bottom"
          okText="Entfernen"
          cancelText="Abbrechen"
        >
          <Button danger type={"ghost"} className={styles.select} icon={<DeleteOutlined translate={"no"} />}>
            Monat
          </Button>
        </Popconfirm>
      </Tooltip>

      <Tooltip title={"Ausgewähltes Jahr entfernen"}>
        <Popconfirm
          title="Wollen Sie dieses Jahr wirklich entfernen?"
          style={{ color: 'red' }}
          onConfirm={handleYearRemove}
          placement="bottom"
          okText="Entfernen"
          cancelText="Abbrechen"
        >
          <Button danger type={"ghost"} icon={<DeleteOutlined translate={"no"} />}>
            Jahr
          </Button>
        </Popconfirm>
      </Tooltip>

      <div className={styles.filter}>
        {types && (
          <Select
            value={actualType}
            onChange={handleType}
            className={[styles.select, styles.type].join(" ")}
            placeholder={"Typ filtern"}
            mode={"multiple"}
          >
            {types.map((type: Type) => (
              <Select.Option key={type.id} value={type.id}>{type.name}</Select.Option>
            ))}
          </Select>
        )}

        {areas && (
          <Select
            value={actualArea}
            onChange={handleArea}
            className={[styles.select, styles.type].join(" ")}
            placeholder={"Bereich filtern"}
            allowClear
          >
            {areas.map((area: Area) => (
              <Select.Option key={area.id} value={area.id}>{area.name}</Select.Option>
            ))}
          </Select>
        )}
        {me && (me.role === "Admin" || me.role === "PaidCustomer") ? (
          <Download events={events} areas={areas} types={types} year={actualYear} actualTypes={actualType} actualArea={actualArea} />
        ) : (
          <Tooltip title={"Downloads sind nur mit entsprechend bezahltem Abo möglich."}>
            <Button type={'primary'} style={{ marginRight: '.5rem' }} disabled>Download</Button>
          </Tooltip>
        )}


        <Button type={'primary'} onClick={() => reset()}><SyncOutlined translate={'no'} /></Button>
      </div>
    </div>
  ) : null
  )
}