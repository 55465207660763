import React, { useState, useRef } from 'react';
import { Input, Button, Tooltip, Popconfirm, notification } from 'antd';
import {
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons';
import styles from './table.module.scss';
import { useRemoveAreaMutation } from '../../../generated/graphql';
import { columnSearchProps } from '../Utils/table';
import { TableComponent } from '../Utils/TableComponent';

type Area = {
  id: string;
  name: string;
  tag: string;
}

interface categoryTableProps {
  loading: boolean
  refetch: Function
  edit: Function
  areas: Area[]
}

export type DropDownType = {
  setSelectedKeys: any,
  selectedKeys: any,
  confirm: any,
  clearFilters: any
}

export const AreasTable: React.FC<categoryTableProps> = ({ areas, loading, refetch, edit }) => {
  const [removeArea] = useRemoveAreaMutation()
  const [query, setQuery] = useState("");
  const [column, setColumn] = useState("");
  const searchRef = useRef<Input>(null);

  const remove = async (item) => {
    const removed = await removeArea({
      variables: {
        id: item.id
      }
    })
    if (removed.data.removeArea) {
      notification.success({
        message: `Bereich ${item.name} wurde erfolgreich entfernt!`
      })
      refetch();
    } else {
      notification.error({
        message: 'Bereich konnte nicht entfernt werden!'
      })
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      ...columnSearchProps('name', searchRef, column, query, setColumn, setQuery),
    },
    {
      title: 'Kürzel',
      dataIndex: 'tag',
      key: 'tag',
      sorter: (a: any, b: any) => a.tag.localeCompare(b.tag),
      ...columnSearchProps('tag', searchRef, column, query, setColumn, setQuery),
    },
    {
      title: () => <div className={styles.centerTitle}>Aktionen</div>,
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (_: number, record) => (
        <div className={styles.centerWrapper}>
          <Button
            shape={"circle"}
            type={"primary"}
            size={'small'}
            className={styles.actionButton}
            onClick={() => edit(record)}
            icon={<EditOutlined translate={'no'} />}
          />

          <Tooltip title={"Bereich entfernen"}>
            <Popconfirm
              title="Wollen Sie diesen Bereich wirklich entfernen?"
              style={{ color: 'red' }}
              onConfirm={() => remove(record)}
              placement="left"
              okText="Entfernen"
              cancelText="Abbrechen"
            >
              <Button
                danger
                shape={"circle"}
                type={"primary"}
                size={'small'}
                className={styles.actionButton}
                icon={<DeleteOutlined translate={'no'} />}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      )
    }
  ]

  return <TableComponent data={areas} loading={loading} columns={columns} />
}