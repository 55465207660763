import React, { FormEvent, useContext } from 'react';
import { useLoginMutation, MeDocument, MeQuery } from '../../generated/graphql';
import styles from "./styles.module.scss";
import { setAccessToken } from '../../accessToken';
import Loader from '../Loader';
import { DataContext } from '../../Context/DataContext';
import { Button, Input, message } from 'antd';

const { useState } = React;

interface loginProps {
  close: any
}

const Login: React.FC<loginProps> = ({ close }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { data, loading, error }] = useLoginMutation();
  const { me } = useContext(DataContext);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const response = await login({
      variables: {
        email,
        password
      },
      update: (store, { data }) => {
        if (!data) {
          return null;
        }

        store.writeQuery<MeQuery>({
          query: MeDocument,
          data: {
            me: data.login.user
          }
        });
      }
    });

    if (response && response.data.login) {
      message.success("Login erfolgreich")
      localStorage.setItem('jid', response.data.login.refreshToken)
      setAccessToken(response.data.login.accessToken);
    } else {
      message.error("Fehler beim Login")
    }
    close()
  }

  return (!me ?
    (
      <form className={styles.loginContainer} onSubmit={handleSubmit}>
        {!loading && !error && (
          <>
            <Input
              id="email"
              value={email}
              placeholder={"E-mail"}
              type={"email"}
              onChange={({target}) => setEmail(target.value)}
            />

            <Input
              id="pass"
              value={password}
              placeholder={"Passwort"}
              type={"password"}
              className={styles.input}
              onChange={({target}) => setPassword(target.value)}
            />
            {error && (
              <div className={styles.error}>Login Failed, please try again.</div>
            )}
            <Button
              htmlType={"submit"}
              className={styles.button}
              onClick={handleSubmit}
              type={'primary'}
            >
              Login
            </Button>
          </>
        )}
        {loading && !data && <Loader />}
        {!loading && !!data && !error && <div>You are now logged in</div>}
        {!loading && error && (
          <div>Ooops, something went wrong.</div>
        )}
      </form>
    ) : <div>Logged In</div>
  );
}

export default Login;
