import React, { createContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useMeQuery } from "../generated/graphql";

export type User = {
  id: string
  name: string
  lastname: string
  dsgvo: boolean
  email: string
  verified: boolean
  role: string
}

type ContextProps = {
  location?: any
  me?: User
};
// ----------------------------------------------------------------------------
// Context / Provider / Consumer
// ----------------------------------------------------------------------------
export const DataContext = createContext<Partial<ContextProps & RouteComponentProps>>({});;

const ContextProvider = ({ children, location, history }) => {
  const { data: meData } = useMeQuery();

  // console.log(configData && configData.configurations)

  return (
    <DataContext.Provider
      value={{
        me: meData && meData.me
      }}
    >
      {children}
    </DataContext.Provider>
  )
};

export const DataProvider = ContextProvider;

export const ContextConsumer = DataContext.Consumer;
