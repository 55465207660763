import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface fontProps {
  icon: IconProp
  boxed?: boolean
  color?: string
  size?: SizeProp
}

export const FaIcon: React.FC<fontProps> = ({icon, boxed = false, color}) => {
  return (boxed ?
    (
      <span role={'img'} aria-label={'icon'} className={'anticon'}>
        <FontAwesomeIcon icon={icon} color={color} />
      </span>
    ) : (
      <FontAwesomeIcon icon={icon} color={color} />
    )
  );
}