import React, { useEffect, useState } from 'react';
import { Button, Input, notification } from 'antd';
import styles from './categories.module.scss';
import {
  SaveOutlined
} from '@ant-design/icons';
import { Area, useCreateAreaMutation, useUpdateAreaMutation } from '../../../generated/graphql';

interface createCatProps {
  reset: Function
  area?: Area
}

export const CreateArea: React.FC<createCatProps> = ({ reset, area }) => {
  const [createArea] = useCreateAreaMutation();
  const [updateArea] = useUpdateAreaMutation();
  const [name, setName] = useState<string>();
  const [tag, setTag] = useState<string>();
  const [disabled, setDisabled] = useState(true);


  const handleSave = async () => {
    const saved = await createArea({
      variables: {
        name,
        tag
      }
    });

    if (saved.data.createArea) {
      notification.success({
        message: `Bereich ${name} gespeichert!`
      });
      setName(null);
      setTag(null);
      reset();
    } else {
      notification.error({
        message: `Konnte ${name} nicht speichern!`
      });
    }
  }

  const handleUpdate = async () => {
    const updated = await updateArea({
      variables: {
        id: area.id,
        name,
        tag
      }
    });

    if (updated.data.updateArea) {
      notification.success({
        message: `Bereich ${name} geupdated!`
      });
      setName(null);
      setTag(null);
      reset();
    } else {
      notification.error({
        message: `Konnte ${name} nicht updaten!`
      });
    }
  }

  useEffect(() => {
    if ((tag && tag.length > 0) && (name && name.length > 0)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [name, tag]);

  useEffect(() => {
    if (area) {
      setName(area.name)
      setTag(area.tag)
    }
  }, [area])

  return (
    <div className={styles.createContainer}>
      <div className={styles.name}>
        <h3 className={styles.headline}>Bereich {area ? 'Updaten' : 'Erstellen'}</h3>
        <Input size={'large'} placeholder={'Name'} value={name} allowClear={true} onChange={({ target }) => setName(target.value)} />
        <Input size={'large'} placeholder={'Kürzel'} value={tag} allowClear={true} onChange={({ target }) => setTag(target.value)} />
        <Button
          type={'primary'}
          size={'large'}
          className={styles.saveButton}
          disabled={disabled}
          onClick={area ? handleUpdate : handleSave}
          icon={<SaveOutlined translate={'no'} />}
        >
          {"Speichern"}
        </Button>
      </div>
    </div>
  );
}