import React, { FormEvent, useContext } from "react";
import styles from "./styles.module.scss";
import { useRegisterMutation } from "../../generated/graphql";
import Loader from "../Loader";
import { Button, Input, message } from "antd";
import { DataContext } from "../../Context/DataContext";
import Checkbox from "antd/lib/checkbox/Checkbox";

const { useState } = React;

const Register: React.FC = () => {
  const { me } = useContext(DataContext);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [dsgvo, setDsgvo] = useState(false);
  const [register, { data, loading, error }] = useRegisterMutation();
  const role = "Customer";

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (name && lastname && email && password && dsgvo) {
      const reg = await register({
        variables: {
          email,
          name,
          lastname,
          dsgvo,
          password,
          role
        }
      });

      if (reg.data.register) {
        message.success("Ihr Account wurde erfolgreich angelegt.");
      } else {
        message.error("Es existiert bereits ein Account mit dieser Email adresse.");
      }
    } else {
      message.info("Bitte füllen Sie alle erforderlichen Felder aus.");
    }
  };

  return (!me ? (
    <form className={styles.registerContainer} onSubmit={handleSubmit}>
      {!loading && (!data || !data.register) && (
        <>
          <Input
            value={name}
            id="name"
            required={true}
            placeholder={"Name"}
            type={"text"}
            onChange={({target}) => setName(target.value)}
          />
          <Input
            value={lastname}
            id="lastname"
            required={true}
            placeholder={"Nachname"}
            className={styles.input}
            type={"text"}
            onChange={({target}) => setLastname(target.value)}
          />
          <Input
            value={email}
            id="email"
            required={true}
            placeholder={"E-mail"}
            className={styles.input}
            type={"email"}
            onChange={({target}) => setEmail(target.value)}
          />
          <Input
            value={password}
            id="password"
            required={true}
            className={styles.input}
            placeholder={"Passwort"}
            type={"password"}
            onChange={({target}) => setPassword(target.value)}
          />

          <Checkbox value={String(dsgvo)}
            id="dsgvo"
            checked={dsgvo}
            onChange={({target}) => setDsgvo(target.checked)}
            className={styles.check}
          >
            Hiermit akzeptieren Sie unsere <a href={'https://heislitz.com/datenschutzerklaerung/'} target={'_blank'} title={'zu unserer Datenschutzbestimmung'}>Datenschutzbestimmungen</a>
          </Checkbox>

          <Button
            htmlType={"submit"}
            className={styles.button}
            onClick={handleSubmit}
            type={'primary'}
            disabled={!dsgvo}
          >
            Registrieren
          </Button>
        </>
      )}
      {loading && (
        <Loader />
      )}
      {!loading && data && data.register && (
        <div>
          <p>Vielen Dank für Ihre Registrierung!</p>
          <p>Bitte überpfrüfen Sie Ihre E-Mails und vereifizieren Ssie Ihren Account.</p>
        </div>
      )}
      {!loading && error && (
        <div>Ooops, da ist etwas schief gelaufen.</div>
      )}
    </form>
  ) : <div>Logged In</div>);
};

export default Register;
