import { Moment } from 'moment';

export type Day = {
  id: number
  month: number
  year: number
  events: any
}

type Area = {
  id: string
  name: string
}

export type Event = {
  id: string
  name?: string
  areas?: Area[]
  description?: string | null
  alarmDescription?: string | null
  startTime?: string | null
  endTime?: string | null
  day: number
  month: number
  year: {
    id: string
    active?: boolean
    value: number
  }
  type: {
    id: string
  }
}

export const parseData = (value: Moment, data?: Event[], actualDate?: Moment) => {
  let listData: Event[] = [];

  const currentMonth = value.month();
  const currentYear = Number(value.format('YYYY'))
  const monthDay = data && data.filter(d => d.month === currentMonth && d.year.value === currentYear && d.day === value.date());

  if (monthDay && value) {
    listData = [
      ...monthDay
    ];
  }

  return listData || [];
}

export const parseDays = (entries) => {
  let daysArr = []
  entries.forEach(month => {
    Object.entries(month.days).forEach(([key, val]) => {
      daysArr.push({
        id: parseInt(key),
        month: month.month,
        year: month.year,
        events: val
      });
    });
  });

  return daysArr;
}
