import React, { useState, useRef } from 'react';
import { Input, Button, Tooltip, Popconfirm, notification } from 'antd';
import moment from 'moment';
import {
  DeleteOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import styles from './table.module.scss';
import { useRemovePatternMutation } from '../../../generated/graphql';
import { columnSearchProps } from '../Utils/table';
import { TableComponent } from '../Utils/TableComponent';

interface patternTableProps {
  loading: boolean
  refetch: Function
  edit: Function
  patterns: any[]
}

export type DropDownType = {
  setSelectedKeys: any,
  selectedKeys: any,
  confirm: any,
  clearFilters: any
}

export const PatternTable: React.FC<patternTableProps> = ({ patterns, loading, refetch }) => {
  const [removePattern] = useRemovePatternMutation();
  const [query, setQuery] = useState("");
  const [column, setColumn] = useState("");
  const searchRef = useRef<Input>(null);

  const remove = async (item) => {
    const removed = await removePattern({
      variables: {
        id: item.id
      }
    })
    if (removed.data.removePattern) {
      notification.success({
        message: `Vorlage erfolgreich entfernt!`
      })
      refetch();
    } else {
      notification.error({
        message: 'Vorlage konnte nicht entfernt werden!'
      })
    }
  }

  const download = (pattern) => {
    let output: any[] = [];
    const fields = pattern.number + 1;
    const year = pattern.year;
    const months: number[] = Array.apply(0, Array(12)).map((_,i) => i);
    const maxDays: number[] = Array.apply(0, Array(31)).map((_,i) => i + 1);

    output.push(months.map(m => {
      const month = moment().set({ year }).month(m)
      const fieldCount = months.length !== (m + 1) ? fields : fields - 1;
      return month.format('MMMM') + Array.apply(0, Array(fieldCount)).map((_,i) => ';').join('')
    }).join(''));

    maxDays.forEach(day => {
      const stuff = months.map(m => {
        const month = moment([parseInt(year), m, day]);
        const fieldCount = months.length !== (m + 1) ? fields : fields - 1;
        return (month.isValid() ? day : '') + Array.apply(0, Array(fieldCount)).map((_,i) => ';').join('')
      }).join('')
      output.push(stuff);
    });

    const element = document.createElement("a");
    const file = new Blob([output.join('\n')], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = `vorlage_${year}_${fields - 1}.csv`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  const columns = [
    {
      title: 'Jahr',
      dataIndex: 'year',
      key: 'year',
      sorter: (a: any, b: any) => a.year.localeCompare(b.year),
      ...columnSearchProps('year', searchRef, column, query, setColumn, setQuery),
    },
    {
      title: 'Anzahl Felder pro Tag',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: () => <div className={styles.centerTitle}>Aktionen</div>,
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (_: number, record) => (
        <div className={styles.centerWrapper}>
          <Tooltip title={"Vorlage herunterladen"}>
            <Button
              shape={"circle"}
              type={"primary"}
              size={'small'}
              className={styles.actionButton}
              onClick={() => download(record)}
              icon={<DownloadOutlined translate={'no'} />}
            />
          </Tooltip>

          <Tooltip title={"Vorlage entfernen"}>
            <Popconfirm
              title="Wollen Sie diese Vorlage wirklich entfernen?"
              style={{ color: 'red' }}
              onConfirm={() => remove(record)}
              placement="left"
              okText="Entfernen"
              cancelText="Abbrechen"
            >
              <Button
                danger
                shape={"circle"}
                type={"primary"}
                size={'small'}
                className={styles.actionButton}
                icon={<DeleteOutlined translate={'no'} />}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      )
    }
  ]

  return <TableComponent data={patterns} loading={loading} columns={columns} />
}