import React, { Suspense } from 'react'
import Card from 'antd/es/card';
import List from 'antd/es/list';
import { Remove } from './Remove';

import styles from './styles.module.scss';
import { useUsersQuery } from '../../../generated/graphql';
import Loader from '../../../Components/Loader';
import { Role } from './Role';
import { Session } from './Session';
import { Verified } from './Verified';

export const User: React.FC = () => {
  const { data, refetch } = useUsersQuery();

  return (
    <Card
      title={"Users"}
      headStyle={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#003363"
      }}
      className={styles.content}
      bordered={false}
    >
      <Suspense fallback={<Loader />}>
        {data && <List
          bordered
          dataSource={data.users}
          renderItem={item => (
            <List.Item
              actions={[
                <Verified user={item} refetch={refetch} />,
                <Role user={item} refetch={refetch} />,
                <Session user={item} refetch={refetch} />,
                <Remove user={item} refetch={refetch} />
              ]}
            >
              <div><strong>{item.name} {item.lastname}</strong> | {item.email}</div>
            </List.Item>
          )}
        />}
      </Suspense>
    </Card>
  );
}