import React, { ChangeEvent, useState } from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/de';
import localeDe from 'antd/es/date-picker/locale/de_DE';
import { Input, Select, TimePicker } from 'antd';
import { InputEvent, useAreasQuery } from '../../../generated/graphql';
import { Type } from '../../../Utils/csv';

import styles from './calendar.module.scss';

interface NewEventProps {
  set: Function
  values: InputEvent
  types: Type[]
  date?: Moment
}

export const NewEvent: React.FC<NewEventProps> = ({ date, set, values, types }) => {
  const { data: areaData } = useAreasQuery();
  const [startTime, setStartTime] = useState<Moment>();
  const [endTime, setEndTime] = useState<Moment>();
  const format = 'HH:mm';

  const handleType = (typeId, option) => {
    set({
      ...values,
      type: typeId,
      name: option.name
    });
  }

  const handleArea = (areaIds) => {
    set({
      ...values,
      areas: areaIds
    });
  }

  const handleStart = (startTime) => {
    setStartTime(startTime);
    set({
      ...values,
      startTime: startTime.format(format)
    });
  }

  const handleEnd = (endTime) => {
    setEndTime(endTime);
    set({
      ...values,
      endTime: endTime.format(format)
    });
  }

  const handleDesc = ({target}: ChangeEvent<HTMLInputElement>) => {
    set({
      ...values,
      description: target.value
    })
  }

  const handleAlarm = ({target}: ChangeEvent<HTMLInputElement>) => {
    set({
      ...values,
      alarmDescription: target.value
    })
  }

  const getDisabledHours = () => {
    const hours: number[] = [];
    for(let i = 0; i < (startTime ? startTime.hour() : moment().hour()); i++){
      hours.push(i);
    }
    return hours;
  }

  return (
    <div className={styles.createNew}>
      <div className={styles.firstRow}>
        <TimePicker
          className={styles.input}
          locale={localeDe}
          minuteStep={15}
          showNow={false}
          format={format}
          placeholder={'Startzeit'}
          onChange={(value) => handleStart(value)}
          value={startTime}
        />
        <TimePicker
          className={styles.input}
          locale={localeDe}
          minuteStep={15}
          showNow={false}
          format={format}
          disabledHours={getDisabledHours}
          placeholder={'Endzeit'}
          onChange={(value) => handleEnd(value)}
          value={endTime}
        />
        <Input className={styles.input} type={'text'} name={'description'} onChange={(v) => handleDesc(v)} placeholder={'Beschreibung'} />
        <Input className={styles.input} type={'text'} name={'alarmDescription'} onChange={handleAlarm} placeholder={'Alarm Beschreibung'} />
      </div>
      <div className={styles.secondRow}>
        <Select
          className={styles.select}
          aria-required={true}
          placeholder={'Abfuhrtyp wählen'}
          onChange={(value, option) => handleType(value, option)}
          showSearch
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }}
        >
          {types.map(type => (
            <Select.Option key={type.id} value={type.id} name={type.name}>{type.name}</Select.Option>
          ))}
        </Select>

        {areaData && (
          <Select
            className={styles.select}
            aria-required={true}
            placeholder={'Bereiche wählen'}
            onChange={(value) => handleArea(value)}
            mode={'multiple'}
          >
            {areaData.areas.map(area => (
              <Select.Option key={area.id} value={area.id} name={area.name}>{area.name}</Select.Option>
            ))}
          </Select>
        )}
      </div>
    </div>
  );
}