import React, { useState } from 'react';
import { Button, Input, notification, Tooltip, Upload } from 'antd';
import {
  UploadOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import styles from './calendar.module.scss';
import { useAreasQuery, useTypesQuery, useCreateYearMutation, useCreateEventsMutation } from '../../../generated/graphql';
import { Month, readCSV, Type } from '../../../Utils/csv';
import { parseDays } from '../../../Utils/cal';

interface ImportProps {
  set: Function
  importData: Month[]
  reset: Function
}

type Event = {
  areas: any[]
  type: Type
  timing: {
    date: number
    month: number
    year: number
  }
}

type InputEvent = {
  name: string
  areas: string[]
  type: string
  day: number
  month: number
}

export const Import: React.FC<ImportProps> = ({ set, importData, reset }) => {
  const { data: typesData } = useTypesQuery();
  const { data: areasData } = useAreasQuery();
  const [createYear] = useCreateYearMutation();
  const [createEvent] = useCreateEventsMutation()

  const [file, setFile] = useState<File>(null);
  const [year, setYear] = useState<number>()
  const [fields, setFields] = useState<number>()
  const [read, setRead] = useState<boolean>(false)

  const handleChange = (file: File) => {
    setFile(file);

    return true;
  }

  const handleFile = async () => {
    if(typesData && areasData && year) {
      const data = await readCSV(year, fields, file, typesData.types, areasData.areas);
      set(data)
      setRead(true);
    }
  }

  const handleRemove = () => {
    setFile(null);
    set(null)
    setRead(false)
  }

  const getEvents = (days): Event[] => {
    const events: Event[] = []
    days.forEach(day => {
      const timing = { date: day.id, month: day.month, year: day.year };
      day.events.forEach((ev) => {
        const event: Event = {
          type: typesData.types.find(t => t.id === ev.type),
          areas: ev.areas.map(area => area.id),
          timing
        }
        events.push(event);
      })
    });

    return events;
  }

  const saveData = async () => {
    const yearSaved = await createYear({
      variables: {
        value: year
      }
    });

    if (!yearSaved.data.createYear) {
      notification.error({
        message: `Ein Fehler ist aufgetreten.`
      });
    } else {
      const days = parseDays(importData).sort((a, b) => a.month - b.month);
      const events = getEvents(days);
      const inputEvents: InputEvent[] = [];

      events.forEach(async (event) => {
        inputEvents.push({
          name: event.type.name,
          areas: event.areas,
          type: event.type.id,
          day: event.timing.date,
          month: event.timing.month,
        });
      });

      const createdEvents = await createEvent({
        variables: {
          events: inputEvents,
          year: yearSaved.data.createYear
        }
      });

      if (createdEvents.data.createEvents) {
        notification.success({
          message: "Daten komplett importiert!"
        });
        reset();
        setYear(null);
        setFields(null);
        handleRemove();
      } else {
        notification.error({
          message: `Ein Fehler ist aufgetreten!`
        });
      }
    }
  }

  return (
    <div className={styles.import}>
      <h3 className={styles.headline}>Import</h3>
      {!file && (
        <Upload accept={".csv"} customRequest={() => {}} beforeUpload={handleChange} showUploadList={false}>
          <Button type={"primary"} className={styles.button} icon={<UploadOutlined translate={'no'} />}>CSV auswählen</Button>
        </Upload>
      )}
      {file && (
        <Tooltip title={"Klicken um aktuelle Datei zu entfernen"}>
          <Button danger className={styles.button} type={'default'} icon={<DeleteOutlined translate={"no"}/>} onClick={handleRemove}>{file.name}</Button>
        </Tooltip>
      )}
      <Input className={styles.input} value={year} onChange={({target}) => setYear(parseInt(target.value))} type={'number'} placeholder={"Jahr"} />
      <Input className={styles.input} value={fields} onChange={({target}) => setFields(parseInt(target.value))} type={'number'} placeholder={"Felder"} />
      <Button className={styles.button} type={"primary"} disabled={file && year ? false : true} onClick={handleFile}>Einlesen</Button>
      <Button className={styles.button} type={"primary"} disabled={importData && year && read ? false : true} onClick={saveData}>Speichern</Button>
    </div>
  );
}