import React, { useState } from 'react';
import { Button, notification, Tooltip, Upload } from 'antd';
import {
  UploadOutlined,
  DeleteOutlined
} from '@ant-design/icons';

import styles from './streets.module.scss';
import { readStreetsCSV } from '../../../Utils/csv';
import { InputStreet, useCreateStreetsMutation } from '../../../generated/graphql';

interface ImportStreetsProps {
  reset: Function
}

export const ImportStreets: React.FC<ImportStreetsProps> = ({ reset }) => {
  const [file, setFile] = useState<File>(null);
  const [importStreets] = useCreateStreetsMutation()

  const handleChange = (file: File) => {
    setFile(file);

    return true;
  }

  const handleRemove = () => {
    setFile(null);
  }

  const saveData = async () => {
    const data: InputStreet[] = await readStreetsCSV(file);

    const created = await importStreets({
      variables: {
        streets: data
      }
    });

    if (created.data.createStreets) {
      notification.success({
        message: 'Straßen erfolgreich importiert!'
      });
      reset();
    } else {
      notification.error({
        message: "Straßen konnten nicht importiert werden.",
        description: "Bitte prüfen Sie ihre CSV oder sprechen Sie uns direkt an."
      });
    }
  }

  return (
    <div className={styles.streetsImport}>
      <h3 className={styles.headline}>Straßen importieren</h3>
      {!file && (
        <Upload accept={".csv"} customRequest={() => {}} beforeUpload={handleChange} showUploadList={false}>
          <Button type={"primary"} className={styles.button} icon={<UploadOutlined translate={'no'} />}>CSV auswählen</Button>
        </Upload>
      )}
      {file && (
        <Tooltip title={"Klicken um aktuelle Datei zu entfernen"}>
          <Button danger className={styles.button} type={'default'} icon={<DeleteOutlined translate={"no"}/>} onClick={handleRemove}>{file.name}</Button>
        </Tooltip>
      )}
      <Button className={styles.saveButton} type={"primary"} disabled={!file} onClick={saveData}>Speichern</Button>
    </div>
  );
}