import React, { useEffect, useState } from "react";
import moment from 'moment';
import {
  CloseOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { animated as a, useTrail } from "react-spring";
import { Spring } from "react-spring/renderprops";
import Login from "../../Components/Login";
import Register from "../../Components/Register";
import styles from "./styles.module.scss";
import { Button, notification, Popover, Tabs } from "antd";
import { Link } from "react-router-dom";
import { useLogoutMutation, useMeQuery } from "../../generated/graphql";

const { TabPane } = Tabs;

const Home: React.FC = () => {
  const [add, setAdd] = useState(false);
  const [pop, setPop] = useState(false);
  const [logout] = useLogoutMutation();
  const { data, refetch } = useMeQuery();

  const open = true;
  const trail = useTrail(3, {
    config: { mass: 8, tension: 500, friction: 200 },
    opacity: open ? 1 : 0,
    y: open ? 2000 : 0,
    from: { opacity: 0, y: 2000 },
    delay: 2050
  });

  const handleVisibility = visible => {
    setPop(visible)
  }

  const handleLogout = async () => {
    const loggedOut = await logout();

    if (loggedOut.data.logout) {
      notification.success({
        message: 'Erfolgreich ausgeloggt!'
      });
      setPop(false);
      localStorage.setItem('jid', '');
      await refetch();
      window.location.reload();
    } else {
      notification.error({
        message: 'Ausloggen nicht möglich!'
      });
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setAdd(true);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={add ? [styles.background, styles.action].join(" ") : styles.background} />
      <Popover
        content={data && !data.me ?
          (
            <Tabs defaultActiveKey="1" centered>
              <TabPane tab="Login" key="1">
                <Login close={() => {}} />
              </TabPane>
              <TabPane tab="Registrieren" key="2">
                <Register />
              </TabPane>
            </Tabs>
          ) : (
            <div className={styles.grid}>
              <Link to={{ pathname: '/userspace' }}>
                <Button type={'link'}>Panel</Button>
              </Link>
              <Button type={'link'} onClick={handleLogout}>Logout</Button>
            </div>
          )
        }
        title={null}
        visible={pop}
        onVisibleChange={handleVisibility}
        placement="bottomRight"
        trigger={'click'}
      >
        <Button
          type={'text'}
          size={'large'}
          className={styles.menu}
          icon={pop ? <CloseOutlined style={{ color: '#fff' }} translate={'no'} /> : <MenuOutlined translate={'no'} style={{color: '#fff'}} />}
        ></Button>
      </Popover>
      <div className={styles.hide}>
        <h2>Login</h2>
        <Login close={() => {}} />
      </div>
      <div className={styles.hide}>
        <h2>Register</h2>
        <Register />
      </div>

      <div className={styles.main}>
        <Spring from={{ opacity: 0 }} to={{ opacity: 1 }} delay={2000} config={{ mass: 8, tension: 2000, friction: 200 }}>
          {ani => (
            <Spring from={{ x: 100 }} to={{ x: 0 }} delay={2000} config={{ mass: 8, tension: 2000, friction: 200 }}>
              {props => (
                <div style={{transform: `translate3d(0,${props.x}px,0)`, opacity: ani.opacity}} className={styles.headline}>
                  Willkommen zum Konfigurator für kommunale Abfallkalender.
                </div>
              )}
            </Spring>
          )}
        </Spring>
        <Spring from={{ x: 200 }} to={{ x: 0 }} delay={2100} config={{ mass: 8, tension: 2000, friction: 200 }}>
          {props => (
            <div className={styles.container} style={{transform: `translate3d(0,${props.x}px,0)`}}>
              {trail.map(({ y, ...rest }, index) => (
                <a.div
                  key={index}
                  className={styles.action}
                  style={{ ...rest, transform: `translate(${y}px,0,0)` }}
                >
                  <h2>Action</h2>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                </a.div>
              ))}
            </div>
          )}
        </Spring>
      </div>
      <div className={styles.footer}>
        <a href={'https://www.heislitz.com/impressum/'} className={styles.link} title={'Impressum'} target={'_blank'}>Impressum</a> Copyright © {moment().format('YYYY')} Heislitz Solutions
      </div>
    </div>
  );
};

export default Home;