import React, { Suspense } from 'react';
import { Table } from 'antd';
import Loader from '../../../Components/Loader';
import { SpinProps } from 'antd/lib/spin';
import { ExpandedRowRender, DefaultRecordType } from 'rc-table/es/interface'
import { TablePaginationConfig } from 'antd/lib/table';

interface categoryTableProps {
  loading: boolean | SpinProps
  data: any[]
  columns: any[]
  rowSelection?: boolean
  selected?: Function
  expandable?: {
    expandedRowRender: ExpandedRowRender<DefaultRecordType>
    rowExpandable: (record: DefaultRecordType) => boolean;
    expandIconColumnIndex?: number
    indentSize?: number
  }
  bordered?: boolean
  selectedItems?: any[]
  pagination?: TablePaginationConfig
}

export type DropDownType = {
  setSelectedKeys: any,
  selectedKeys: any,
  confirm: any,
  clearFilters: any
}

export const TableComponent: React.FC<categoryTableProps> = ({
  data,
  columns,
  loading,
  rowSelection,
  selected,
  expandable,
  bordered = false,
  selectedItems,
  pagination
}) => {
  const selection = {
    onChange: (_, selectedRows) => {
      selected(selectedRows);
    },
    selectedRowKeys: selectedItems
  }

  return (
    <Suspense fallback={<Loader />}>
      {data && (
        <Table
          rowKey="id"
          loading={loading}
          bordered={bordered}
          size={"small"}
          tableLayout={"fixed"}
          dataSource={data}
          columns={columns}
          rowSelection={rowSelection ? {
            type: 'checkbox',
            ...selection,
          } : null}
          expandable={expandable}
          pagination={pagination}
        />
      )}
    </Suspense>
  );
}
