import React from 'react';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { Button } from 'antd';
import { Builder, Calendar, ComplexDate, Duration } from 'ikalendar';
import 'moment/locale/de';
import { Area, Type } from '../../../Utils/csv';
import { Event } from '../../../Utils/cal';

import styles from './calendar.module.scss';

interface DownloadProps {
  events: Event[]
  year: number
  actualTypes: string[]
  actualArea: string
  types: Type[]
  areas: Area[]
}

type DurationOrEnd = {
  duration?: Duration
  end?: string | ComplexDate
}

export const Download: React.FC<DownloadProps> = ({ events, year, actualTypes, actualArea, types, areas }) => {
  moment.locale('de');

  const createICS = (): Calendar => {
    const calendar: Calendar = {
      version: '2.0',
      prodId: 'Awesome project prodId',
      events: []
    }

    if (events.length > 0) {
      events.forEach(event => {
        const timing = { year: event.year.value, month: event.month, date: event.day };
        const type = types.find(t => t.id === event.type.id);
        let startTime;
        let endTime;

        if (event.startTime) {
          startTime = event.startTime.split(':').join('');
        } else if (type.startTime) {
          startTime = type.startTime.split(':').join('');
        }

        if (event.endTime) {
          endTime = event.endTime.split(':').join('');
        } else if (type.endTime) {
          endTime = type.endTime.split(':').join('');
        }

        const dtStampTime = startTime ? startTime : null;

        let durationOrEnd: DurationOrEnd;

        if (endTime) {
          durationOrEnd = {
            end: moment().set(timing).format('YYYYMMDD') + `T${endTime}00`
          }
        } else {
          durationOrEnd = {
            duration: { days: 1 },
          }
        }

        calendar.events.push({
          uid: uuid(),
          dtStamp: moment().set(timing).format('YYYYMMDD') + `${dtStampTime ? `T${dtStampTime}00` : ''}`,
          start: moment().set(timing).format('YYYYMMDD') + `${dtStampTime ? `T${dtStampTime}00` : ''}`,
          summary: event.description ? event.description : type.description,
          description: event.description ? event.description : type.description,
          location: event.areas.map(a => a.name).join(" "),
          alarms: [
            {
              action: 'DISPLAY',
              trigger: { type: 'DATE-TIME', value: moment().set(timing).subtract(1, 'days').startOf('day').format('YYYYMMDD') },
              description: event.alarmDescription ? event.alarmDescription : type.alarmDescription,
            }
          ],
          ...durationOrEnd
        });
      });
    }

    return calendar;
  }

  const downloadICSFile = async () => {
    const calendar = await createICS();
    let filename = "Cal";
    const extension = ".ics";
    if (actualArea) {
      filename = filename + '_' + areas.find(a => a.id === actualArea).name + '_';
    } else if (actualTypes && actualTypes.length > 0) {
      filename = filename + '_';
    }
    if (actualTypes && actualTypes.length > 0) {
      filename = filename + types.filter(t => actualTypes.includes(t.id)).map(t => t.tag).join('_')
    }
    const builder = new Builder(calendar)
    const data = await builder.build();
    const element = document.createElement("a");
    const file = new Blob([data], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = `${filename}_${year}${extension}`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  return (
    <Button type={"primary"} className={styles.select} onClick={downloadICSFile} disabled={events.length === 0}>
      {events.length > 0 ? `${events.length} ${events.length === 1 ? 'Eintrag' : 'Einträge'} Downloaden` : 'Keine Einträge vorhanden'}
    </Button>
  );
}