import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from './Routes';
import useWindowDimensions from "./Hooks/useWindowDimensions";
import { useSettingsQuery } from "./generated/graphql";

export const Browser: React.FC = () => {
  const [debug, setDebug] = useState(false);
  const { width, height, mobile } = useWindowDimensions();
  const { data } = useSettingsQuery();

  useEffect(() => {
    if (data && data.settings) {
      const debugData = data.settings.find(d => d.name === "debug" || d.name === "Debug");
      if (debugData) {
        setDebug(debugData.value === "true" ? true : false);
      }
    }
  }, [data])

  return (
    <BrowserRouter>
      <Routes />
      {debug && <div className={'debug'}>debug {width} x {height} | Mobile: {mobile ? "Yes" : "No"}</div>}
    </BrowserRouter>
  );
};
