import React, { useState } from 'react';
import { Button, Input, notification } from 'antd';
import styles from './pattern.module.scss';
import { useCreatePatternMutation } from '../../../generated/graphql';

interface CreatePatternProps {
  reset: Function
}

export const CreatePattern: React.FC<CreatePatternProps> = ({ reset }) => {
  const [year, setYear] = useState<string>('');
  const [number, setNumber] = useState<number>();
  const [createPattern] = useCreatePatternMutation();

  const handlePattern = async () => {
    const created = await createPattern({
      variables: {
        year,
        number
      }
    });

    if (created.data.createPattern) {
      notification.success({
        message: 'Vorlage erstellt'
      });
      reset()
      setYear(null);
      setNumber(null);
    } else {
      notification.error({
        message: 'Vorlage konnte nicht erstellt werden.'
      });
    }
  }

  return (
    <div className={styles.container}>
      <h3>Vorlage erstellen</h3>
      <div className={styles.create}>
        <div className={styles.input}>
          <Input
            value={year}
            size={'large'}
            placeholder={'Jahr'}
            onChange={({ target }) => setYear(target.value)}
          />
        </div>
        <div className={styles.input}>
          <Input
            value={number}
            size={'large'}
            placeholder={'Spaltenanzahl'}
            type={'number'}
            onChange={({ target }) => setNumber(parseInt(target.value))}
          />
        </div>
        <Button
          size={'large'}
          className={styles.button}
          type={'primary'}
          onClick={handlePattern}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
}