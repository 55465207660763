import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/de';
import localeDe from 'antd/es/date-picker/locale/de_DE';
import { Button, Input, notification, Popover, TimePicker } from 'antd';
import { ChromePicker } from 'react-color';
import styles from './types.module.scss';
import {
  SaveOutlined
} from '@ant-design/icons';
import { useCreateTypeMutation, useUpdateTypeMutation } from '../../../generated/graphql';
import { Type } from '../../../Utils/csv';


interface createCatProps {
  reset: Function
  type?: Type
}

export const CreateType: React.FC<createCatProps> = ({ reset, type }) => {
  const [createType] = useCreateTypeMutation();
  const [updateType] = useUpdateTypeMutation();
  const [name, setName] = useState<string>();
  const [tag, setTag] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [alarmDescription, setAlarmDescsription] = useState<string>();
  const [startTime, setStartTime] = useState<Moment>();
  const [endTime, setEndTime] = useState<Moment>();
  const [color, setColor] = useState<string>();
  const [disabled, setDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const format = 'HH:mm';

  const handleSave = async () => {
    if (name && tag && description && alarmDescription && color) {
      const saved = await createType({
        variables: {
          name,
          description,
          alarmDescription,
          startTime: startTime ? startTime.format(format) : null,
          endTime: endTime ? endTime.format(format) : null,
          tag,
          color
        }
      });

      if (saved && saved.data.createType) {
        notification.success({
          message: `Typ ${name} erstellt!`
        });
        setName(null);
        setTag(null);
        setDescription(null);
        setAlarmDescsription(null);
        setStartTime(null)
        setEndTime(null)
        setColor(undefined);
        reset();
      } else {
        notification.error({
          message: `Konnte ${name} nicht speichern!`
        });
      }
    } else {
      notification.error({
        message: `Bitte füllen Sie alle Felder aus!`
      });
    }
  }

  const handleUpdate = async () => {
    if (name && tag && description && alarmDescription && color) {
      const updated = await updateType({
        variables: {
          id: type.id,
          name,
          description,
          alarmDescription,
          startTime: startTime ? startTime.format(format) : null,
          endTime: endTime ? endTime.format(format) : null,
          tag,
          color
        }
      });

      if (updated && updated.data.updateType) {
        notification.success({
          message: `Typ ${name} geupdated!`
        });
        setName(null);
        setTag(null);
        setDescription(null);
        setAlarmDescsription(null);
        setStartTime(null)
        setEndTime(null)
        setColor(undefined);
        reset();
      } else {
        notification.error({
          message: `Konnte ${name} nicht updaten!`
        });
      }
    } else {
      notification.error({
        message: `Bitte füllen Sie alle Felder aus!`
      });
    }
  }

  const getDisabledHours = () => {
    const hours: number[] = [];
    for(let i = 0; i < (startTime ? startTime.hour() : moment().hour()); i++){
      hours.push(i);
    }
    return hours;
  }

  const handleColor = ({ hex }) => {
    setColor(hex);
  }

  const handleVisibleChange = visible => {
    setVisible(visible);
  };

  useEffect(() => {
    if ((tag && tag.length > 0) && (name && name.length > 0) && (color && color.length > 0) && description && alarmDescription) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [name, tag, color, startTime, endTime, description, alarmDescription]);

  useEffect(() => {
    if (type) {
      setName(type.name)
      setTag(type.tag)
      setDescription(type.description)
      setAlarmDescsription(type.alarmDescription)
      setStartTime(type.startTime ? moment().set({hours: parseInt(type.startTime.split(':')[0]), minutes: parseInt(type.startTime.split(':')[1])}) : null)
      setEndTime(type.endTime ? moment().set({hours: parseInt(type.endTime.split(':')[0]), minutes: parseInt(type.endTime.split(':')[1])}) : null)
      setColor(type.color)
    }
  }, [type]);

  return (
    <div className={styles.createContainer}>
      <div className={styles.name}>
        <h3 className={styles.headline}>Typ {type ? 'Updaten' : 'Speichern'}</h3>
        <div className={styles.grid}>
          <div>
            <Input size={'large'} className={styles.input} placeholder={'Name'} value={name} allowClear={true} onChange={({ target }) => setName(target.value)} />
            <Input size={'large'} className={styles.input} placeholder={'Kürzel'} value={tag} allowClear={true} onChange={({ target }) => setTag(target.value)} />
            <Input size={'large'} className={styles.input} placeholder={'Beschreibung'} value={description} allowClear={true} onChange={({ target }) => setDescription(target.value)} />
            <Input size={'large'} className={styles.input} placeholder={'Alarm Beschreibung'} value={alarmDescription} allowClear={true} onChange={({ target }) => setAlarmDescsription(target.value)} />
          </div>
          <div>
            <TimePicker
              size={'large'}
              className={styles.input}
              showNow={false}
              locale={localeDe}
              minuteStep={15}
              format={format}
              placeholder={'Start Zeit'}
              onChange={(value) => setStartTime(value)}
              value={startTime ? startTime : null}
            />
            <TimePicker
              size={'large'}
              className={styles.input}
              showNow={false}
              locale={localeDe}
              minuteStep={15}
              format={format}
              disabledHours={getDisabledHours}
              placeholder={'End Zeit'}
              onChange={(value) => setEndTime(value)}
              value={endTime ? endTime : null}
            />
            <Popover
              placement={"right"}
              visible={visible}
              onVisibleChange={handleVisibleChange}
              content={
                <div className={styles.popover}>
                  <ChromePicker disableAlpha={true} onChange={handleColor} color={color} />
                  <Button type={"primary"} className={styles.closeBtn} onClick={() => setVisible(false)}>Close</Button>
                </div>
              }
              title={null}
              trigger="click"
            >
              <Input size={'large'} placeholder={'Farbe'} value={color} readOnly />
            </Popover>
          </div>
        </div>

        <Button
          type={'primary'}
          size={'large'}
          className={styles.saveButton}
          disabled={disabled}
          onClick={type ? handleUpdate : handleSave}
          icon={<SaveOutlined translate={'no'} />}
        >
          {"Speichern"}
        </Button>
      </div>
    </div>
  );
}