import React, { useEffect, useState } from 'react';
import { Button, Input, notification, Select } from 'antd';
import {
  SaveOutlined
} from '@ant-design/icons';
import styles from './streets.module.scss';
import { useAreasQuery, useCreateStreetMutation, useUpdateStreetMutation } from '../../../generated/graphql';

interface createStreetProps {
  reset: Function
  street?: {
    id: string,
    name: string
    area: {
      id: string
    }
  }
}

export const CreateStreet: React.FC<createStreetProps> = ({ reset, street }) => {
  const [name, setName] = useState<string>();
  const [areaId, setAreaId] = useState<string>();
  const { data } = useAreasQuery();
  const [disabled, setDisabled] = useState(true);
  const [createStreet] = useCreateStreetMutation();
  const [updateStreet] = useUpdateStreetMutation();

  const handleChange = ({ target }) => {
    setName(target.value);
  }

  const handleSelect = (value) => {
    setAreaId(value);
  }

  const handleSave = async () => {
    const saved = await createStreet({
      variables: {
        name,
        areaId
      }
    });

    if (saved.data.createStreet) {
      notification.success({
        message: `Area ${name} created!`
      });
      setName(null);
      reset();
    } else {
      notification.error({
        message: `Could not save ${name}!`
      });
    }
  }

  const handleUpdate = async () => {
    const updated = await updateStreet({
      variables: {
        id: street.id,
        name,
        areaId
      }
    });

    if (updated.data.updateStreet) {
      notification.success({
        message: `Area ${name} updated!`
      });
      setName(null);
      reset();
    } else {
      notification.error({
        message: `Could not update ${name}!`
      });
    }
  }

  useEffect(() => {
    if (street) {
      setName(street.name)
      setAreaId(street.area!.id)
    }
  }, [street]);

  useEffect(() => {
    if ((name && name.length > 0) && (areaId && areaId.length > 0)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [name, areaId]);

  return (
    <div className={styles.createContainer}>
      <h3 className={styles.headline}>Straße {street ? 'Updaten' : 'Speichern'}</h3>
      <Input size={'large'} placeholder={'Straßenname'} value={name} allowClear={true} onChange={handleChange} />
      <Select
        placeholder={'Bereich wählen'}
        onChange={handleSelect}
        size={'large'}
        className={styles.select}
        defaultValue={street ? street.area.id : undefined}
      >
        {data && data.areas.map(area => (
          <Select.Option key={area.id} value={area.id}>{area.name}</Select.Option>
        ))}
      </Select>
      <Button
        type={'primary'}
        size={'large'}
        className={styles.saveButton}
        disabled={disabled}
        onClick={street ? handleUpdate : handleSave}
        icon={<SaveOutlined translate={'no'} />}
        >
          {"Speichern"}
        </Button>
    </div>
  );
}