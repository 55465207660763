import React, { useState, useRef } from 'react';
import { Input, Button, Tooltip, Popconfirm, notification } from 'antd';
import {
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import styles from './table.module.scss';
import { useRemoveStreetMutation } from '../../../generated/graphql';
import { columnSearchProps } from '../Utils/table';
import { TableComponent } from '../Utils/TableComponent';

type Street = {
  id: string;
  name: string;
  area: {
    id: string
  }
}

interface streetTableProps {
  loading: boolean
  refetch: Function
  edit: Function
  streets: Street[]
}

export type DropDownType = {
  setSelectedKeys: any,
  selectedKeys: any,
  confirm: any,
  clearFilters: any
}

export const StreetsTable: React.FC<streetTableProps> = ({ streets, loading, refetch, edit }) => {
  const [removeStreet] = useRemoveStreetMutation();
  const [query, setQuery] = useState("");
  const [column, setColumn] = useState("");
  const searchRef = useRef<Input>(null);

  const remove = async (item) => {
    const removed = await removeStreet({
      variables: {
        id: item.id
      }
    })
    if (removed.data.removeStreet) {
      notification.success({
        message: `Straße erfolgreich entfernt!`
      })
      refetch();
    } else {
      notification.error({
        message: 'Straße konnte nicht entfernt werden!'
      })
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      ...columnSearchProps('name', searchRef, column, query, setColumn, setQuery),
    },
    {
      title: 'Bereich',
      dataIndex: 'area',
      key: 'area',
      ...columnSearchProps('area.name', searchRef, column, query, setColumn, setQuery),
      render: (area) => {
        if (column === 'area.name') {
          return <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[query]}
            autoEscape
            textToHighlight={area.name.toString()}
          />
        }
        return area.name;
      }
    },
    {
      title: () => <div className={styles.centerTitle}>Aktionen</div>,
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (_: number, record) => (
        <div className={styles.centerWrapper}>
          <Button
            shape={"circle"}
            type={"primary"}
            size={'small'}
            className={styles.actionButton}
            onClick={() => edit(record)}
            icon={<EditOutlined translate={'no'} />}
          />

          <Tooltip title={"Straße entfernen"}>
            <Popconfirm
              title="Wollen Sie diese Straße wirklich entfernen?"
              style={{ color: 'red' }}
              onConfirm={() => remove(record)}
              placement="left"
              okText="Entfernen"
              cancelText="Abbrechen"
            >
              <Button
                danger
                shape={"circle"}
                type={"primary"}
                size={'small'}
                className={styles.actionButton}
                icon={<DeleteOutlined translate={'no'} />}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      )
    }
  ]

  return <TableComponent data={streets} loading={loading} columns={columns} />
}