import React, { useEffect, useState } from 'react';
import { Moment } from 'moment';
import { Modal, notification, Popover, Tag } from 'antd';
import { Event } from '../../../Utils/cal';
import styles from './calendar.module.scss';
import { Type } from '../../../Utils/csv';
import { InputEvent, useUpdateEventMutation } from '../../../generated/graphql';
import { UpdateEvent } from './UpdateEvent';

interface EventItemProps {
  event: Event
  type: Type
  types: Type[]
  date: Moment
  reset: Function
}

export const EventItem: React.FC<EventItemProps> = ({ event, type, types, date, reset }) => {
  const [open, set] = useState(false);
  const [updateEvent, setUpdateEvent] = useState<InputEvent>(null);
  const [update] = useUpdateEventMutation();

  const handleClick = () => {
    set(true);
  }

  const handleUpdate = async () => {
    const inputEvent = {
      ...updateEvent,
      month: parseInt(date.format('MM')) - 1,
      day: date.date()
    }

    const created = await update({
      variables: {
        event: inputEvent,
        id: event.id
      }
    });

    if (created.data.updateEvent) {
      notification.success({
        message: `${updateEvent.name} (${date.format('DD.MM.YYYY')}) erfolgreich gespeichert.`
      });
      set(false);
      reset()
    } else {
      notification.error({
        message: `${updateEvent.name} (${date.format('DD.MM.YYYY')}) konnte nicht gespeichert werden.`
      });
    }
  }

  useEffect(() => {
    let update = {
      ...event,
      startTime: event.startTime ? event.startTime : null,
      endTime: event.endTime ? event.endTime : null,
      type: event.type.id,
      areas: event.areas.map(a => a.id)
    }
    delete update['__typename'];
    delete update.year;

    setUpdateEvent(update)
  }, []); // eslint-disable-line

  return (
    <>
      <Popover
        trigger={"hover"}
        placement={'right'}
        style={{maxWidth: '200px'}}
        content={
          <div className={styles.areas}>
            {event.areas.map(area => <Tag className={styles.areaTag} key={area.id} color={'#1890FF'}>{area.name}</Tag>)}
          </div>
        }
        title={null}
      >
        <Tag className={styles.tag} color={type.color} onClick={handleClick}>{type.name}</Tag>
      </Popover>
      <Modal
        maskClosable={true}
        visible={open}
        onCancel={() => set(false)}
        onOk={() => handleUpdate()}
        title={`"${type.name}" (${date.format('DD.MM.YYYY')}) bearbeiten`}
        cancelText={'Abbrechen'}
        okText={'Speichern'}
        centered
      >
        {updateEvent && <UpdateEvent date={date} set={setUpdateEvent} values={updateEvent} types={types} />}
      </Modal>
    </>
  );
}