import React, { useEffect, useState } from 'react';
import { DatePicker, Select } from 'antd';
import moment, { Moment } from 'moment';
import Loader from '../../Components/Loader';
import { useEntryEventsQuery, useEntryAreasQuery, useEntryTypesQuery } from '../../generated/graphql';
import { Event } from '../../Utils/cal';
import localeDe from 'antd/es/date-picker/locale/de_DE';

import styles from './styles.module.scss';
import { Download } from '../../Admin/Components/Calendar/Download';
import { Area, Type } from '../../Utils/csv';

interface calProps {
  id: string
}

export const Calendar: React.FC<calProps> = ({ id }) => {
  const { data: eventsData, loading: loadingEvents, error: eError } = useEntryEventsQuery({
    variables: {
      id
    }
  });
  const { data: typesData, loading: loadingTypes, error: tError } = useEntryTypesQuery({
    variables: {
      id
    }
  });
  const { data: areasData, loading: loadingAreas, error: aError } = useEntryAreasQuery({
    variables: {
      id
    }
  });
  const [from, setFrom] = useState<Moment>()
  const [to, setTo] = useState<Moment>()
  const [events, setEvents] = useState<Event[]>()
  const [actualTypes, setTypes] = useState<string[]>();
  const [actualArea, setArea] = useState<string>();
  const [actualYear] = useState<number>();

  const handleFrom = (select) => {
    setFrom(select)
  }

  const handleTo = (select) => {
    const to = moment().set({
      year: parseInt(select.format("YYYY")),
      month: select.format("MM") - 1,
      date: select.daysInMonth()
    });
    setTo(to)
  }

  const filter = () => {
    let filteredEvents: Event[] = [];
    if (actualTypes && actualTypes.length > 0 && !actualArea) {
      filteredEvents = eventsData.entryEvents.filter(event => actualTypes.includes(event.type.id));
    } else if (actualArea && (!actualTypes || actualTypes.length === 0) ) {
      filteredEvents = eventsData.entryEvents.filter(event => event.areas.filter(a => a.id === actualArea).length > 0);
    } else if ((actualTypes && actualTypes.length > 0) && actualArea) {
      const fiteredByArea = eventsData.entryEvents.filter(event => event.areas.filter(a => a.id === actualArea).length > 0);
      filteredEvents = fiteredByArea.filter(event => actualTypes.includes(event.type.id));
    } else {
      filteredEvents = eventsData.entryEvents;
    }

    filteredEvents = filteredEvents.filter(e => e.year.active)

    setEvents(
      filteredEvents.filter((event: Event) => {
        const eventMoment = moment().set({
          year: event.year.value,
          month: event.month,
          date: event.day
        });

        return eventMoment.isBetween(from, to);
      })
    );
  }

  useEffect(() => {
    if (eventsData && typesData) {
      filter()
    }
  }, [eventsData, typesData, actualArea, actualTypes, from, to]) // eslint-disable-line

  useEffect(() => {
    const defaultFrom = moment().set({
      year: parseInt(moment().add('year', 1).format("YYYY")),
      month: 0,
      date: 1
    });
    setFrom(defaultFrom)
    const defaultTo = moment().set({
      year: parseInt(moment().add('year', 1).format("YYYY")),
      month: 11,
      date: moment().add('year', 1).set({month: 11}).daysInMonth()
    });
    setTo(defaultTo)
  }, []);

  if(eError || tError || aError) {
    return <div className={styles.error}>Something went wrong!</div>
  }

  return (
    !events && (loadingEvents || loadingTypes || loadingAreas)  ?
    (
      <Loader />
    ) : (
      <div className={styles.downloadContainer}>
        <DatePicker
          picker={"month"}
          locale={localeDe}
          format={"MMM YYYY"}
          defaultValue={from}
          onChange={handleFrom}
          className={styles.input}
          mode={"month"}
          name={"from"}
          allowClear={false}
        />
        <DatePicker
          picker={"month"}
          locale={localeDe}
          format={"MMM YYYY"}
          defaultValue={to}
          onChange={handleTo}
          className={styles.input}
          mode={"month"}
          name={"to"}
          allowClear={false}
        />

        {typesData && (
          <Select
            value={actualTypes}
            onChange={(value) => setTypes(value)}
            className={styles.input}
            placeholder={"Typ filtern"}
            mode={"multiple"}
          >
            {typesData.entryTypes.map((type: Type) => (
              <Select.Option key={type.id} value={type.id}>{type.name}</Select.Option>
            ))}
          </Select>
        )}

        {areasData && (
          <Select
            value={actualArea}
            onChange={(value) => setArea(value)}
            className={styles.input}
            placeholder={"Bereich filtern"}
            allowClear
          >
            {areasData.entryAreas.map((area: Area) => (
              <Select.Option key={area.id} value={area.id}>{area.name}</Select.Option>
            ))}
          </Select>
        )}
        {typesData && areasData && events && (
          <Download areas={areasData.entryAreas} events={events} types={typesData.entryTypes} year={actualYear} actualTypes={actualTypes} actualArea={actualArea} />
        )}
      </div>
    )
  );
}