import React from 'react';
import { useParams } from 'react-router-dom';
import { Calendar } from './Calendar';
import styles from './styles.module.scss';

export const CalenderExport: React.FC = () => {
  // @ts-ignore
  const { id } = useParams();

  return (id ?
    (
      <Calendar id={id} />
    ) : (
      <div className={styles.error}>No Calender provided!</div>
    )
  );
}