import React from 'react';
import { Button, Select, Tooltip } from 'antd';
import {
  SyncOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import styles from './calendar.module.scss';
import { Type, Area } from '../../../Utils/csv';

interface ListProps {
  types: Type[]
  areas: Area[]
  actualType: string[]
  actualArea: string
  setType: Function
  setArea: Function
  reset: Function
}

export const ListHeader: React.FC<ListProps> = ({
  types,
  areas,
  actualArea,
  actualType,
  setType,
  setArea,
  reset
}) => {
  const handleType = (value: string[]) => {
    setType(value)
  }

  const handleArea = (value: string) => {
    setArea(value)
  }

  return (
    <div className={styles.listHeader}>
      <div>
        Diese Ansicht ist zur schnelleren Bearbeitung der Einträge
        <Tooltip title={'Die Stapelbearbeitung kann dazu führen das nicht alle Einträge nach dem speichern richtig angezeigt werden. Bitte laden Sie im Zweifelsfall die Seite nach dem Speichern neu.'}>
          <InfoCircleOutlined translate={'no'} style={{ marginLeft: '.5rem', fill: '#369eff', color: '#369eff' }} />
        </Tooltip>
      </div>
      <div className={styles.filter}>
        {types && (
          <Select
            value={actualType}
            onChange={handleType}
            className={[styles.select, styles.type].join(" ")}
            placeholder={"Typ filtern"}
            mode={"multiple"}
          >
            {types.map((type: Type) => (
              <Select.Option key={type.id} value={type.id}>{type.name}</Select.Option>
            ))}
          </Select>
        )}

        {areas && (
          <Select
            value={actualArea}
            onChange={handleArea}
            className={[styles.select, styles.type].join(" ")}
            placeholder={"Bereich filtern"}
            allowClear
          >
            {areas.map((area: Area) => (
              <Select.Option key={area.id} value={area.id}>{area.name}</Select.Option>
            ))}
          </Select>
        )}

        <Button type={'primary'} onClick={() => {
          reset()
        }}><SyncOutlined translate={'no'} /></Button>
      </div>
    </div>
  )
}