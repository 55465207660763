import React, { useState } from 'react';
import { Button, Card } from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import styles from './categories.module.scss';
import { AreasTable } from './AreasTable';
import { CreateArea } from './CreateArea';
import { useAreasQuery } from '../../../generated/graphql';

export const Areas: React.FC = () => {
  const [open, set] = useState(false);
  const { data, refetch, loading} = useAreasQuery()
  const [area, setArea] = useState()

  const handleEdit = (area) => {
    setArea(area);
    set(true);
  }

  const reset = () => {
    // setArea(null);
    // set(false);
    refetch();
  }

  return (
    <Card
      title={"Bereiche"}
      headStyle={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#003363"
      }}
      extra={
        <Button shape={"circle"} className={styles.button} type="primary" onClick={() => set(c => !c)}>
          {!open ? <PlusOutlined translate={'no'} /> : <MinusOutlined translate={'no'} />}
        </Button>
      }
      className={styles.content}
      bordered={false}
    >
      {open && <CreateArea area={area} reset={reset} />}
      {data && <AreasTable areas={data.areas} loading={loading} refetch={refetch} edit={handleEdit} />}
    </Card>
  );
}