import React, { Suspense } from 'react';
import { notification, Switch } from 'antd';
import { useSettingsQuery, useUpdateSettingMutation } from '../generated/graphql';
import Loader from '../Components/Loader';

import styles from './styles.module.scss';

export const Settings: React.FC = () => {
  const { data, refetch } = useSettingsQuery();
  const [updateSetting] = useUpdateSettingMutation();

  const handleChange = async (value, id) => {
    const updated = await updateSetting({
      variables: {
        id,
        value: String(value)
      }
    });

    if (updated.data.updateSetting) {
      notification.success({
        message: "Settings updated!"
      });
      refetch()
    } else {
      notification.error({
        message: "Could not update Settings!"
      })
    }
  }

  console.log()

  return (
    <div className={styles.settings}>
      <Suspense fallback={<Loader />}>
        {data && data.settings.map((set, i) => (
          <div key={i} className={styles.setting}>
            <span>{set.name}:</span>
            <Switch
              onChange={(val) => handleChange(val, set.id)}
              checked={set.value === "true" ? true : false}
            />
          </div>
        ))}
      </Suspense>
    </div>
  );
}