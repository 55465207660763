import React, { useEffect, useState } from 'react';
import { notification, Tag, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Moment } from 'moment'
import { InputEvent, useCreateEventMutation, useTypesQuery } from '../../../generated/graphql';
import { parseData, Event } from '../../../Utils/cal';
import Loader from '../../../Components/Loader';
import styles from './calendar.module.scss';
import { EventItem } from './EventItem';
import Modal from 'antd/lib/modal/Modal';
import { NewEvent } from './NewEvent';

interface DateCellRendererProps {
  value: Moment
  actualDate?: Moment
  data?: Event[]
  reset: Function
}

export const DateCellRenderer: React.FC<DateCellRendererProps> = ({ value, data, actualDate, reset }) => {
  const [createEvent] = useCreateEventMutation();
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [newEvent, setNewEvent] = useState<InputEvent>()
  const { data: typesData } = useTypesQuery();
  let listData = parseData(value, data, actualDate);

  const handleOpen = () => {
    setOpen(true)
  }

  const handleSave = async () => {
    const inputEvent = {
      ...newEvent,
      month: parseInt(actualDate.format('MM')) - 1,
      day: actualDate.date()
    }

    const created = await createEvent({
      variables: {
        event: inputEvent,
        year: parseInt(actualDate.format('YYYY'))
      }
    });

    if (created.data.createEvent) {
      notification.success({
        message: `${newEvent.name} (${actualDate.format('DD.MM.YYYY')}) erfolgreich gespeichert.`
      });
      setOpen(false);
      reset()
    } else {
      notification.error({
        message: `${newEvent.name} (${actualDate.format('DD.MM.YYYY')}) konnte nicht gespeichert werden.`
      });
    }
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (actualDate.format('MM') === value.format('MM') && actualDate.date() === value.date()) {
      setActive(true)
    } else {
      setActive(false)
    }
  })

  return (listData && typesData ? (
    <div className={styles.cell} title={value.format('DD.MM.YYYY')}>
      <div className={styles.cellHeader}>{value.date()}</div>
      <div className={styles.cellContent}>
        {listData.map((item) => <EventItem date={value} reset={reset} key={item.id} type={typesData.types.find(t => t.id === item.type.id)} event={item} types={typesData.types} />)}
        {active && (
          <Tooltip title={'Eintrag hinzufügen'}>
            <Tag color={'#1890FF'} style={{ cursor: 'pointer' }} onClick={handleOpen}>
              <PlusOutlined translate={'no'} />
            </Tag>
          </Tooltip>
        )}
      </div>
      <Modal
        maskClosable={true}
        visible={open}
        onCancel={() => setOpen(false)}
        title={`Neuen Eintrag erstellen (${actualDate.format('DD.MM.YYYY')})`}
        cancelText={'Abbrechen'}
        okText={'Speichern'}
        onOk={handleSave}
        centered
      >
        <NewEvent date={value} set={setNewEvent} values={newEvent} types={typesData.types} />
      </Modal>
    </div>
  ) : <Loader />)
}