import React, { useState } from 'react';
import {
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import { Button, Card } from 'antd';
import { CreatePattern } from './CreatePattern';
import { PatternTable } from './PatternTable';
import styles from './pattern.module.scss';
import { usePatternsQuery } from '../../../generated/graphql';

export const Pattern: React.FC = () => {
  const [open, set] = useState(false);
  const { data, refetch, loading } = usePatternsQuery()

  return (
    <Card
      title={"Vorlagen"}
      headStyle={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#003363"
      }}
      extra={
        <Button shape={"circle"} className={styles.button} type="primary" onClick={() => set(c => !c)}>
          {!open ? <PlusOutlined translate={'no'} /> : <MinusOutlined translate={'no'} />}
        </Button>
      }
      className={styles.content}
      bordered={false}
    >
      {open && <CreatePattern reset={refetch} />}
      {data && <PatternTable patterns={data.patterns} refetch={refetch} loading={loading} edit={() => {}} />}
    </Card>
  );
}