import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Calendar, Button, Card, Typography, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import localeDe from 'antd/es/date-picker/locale/de_DE';
import localeEn from 'antd/es/date-picker/locale/en_US';
import {
  CloseOutlined
} from '@ant-design/icons';
import styles from './calendar.module.scss';
import { CalendarHeader } from './CalendarHeader';
import { DateCellRenderer } from './DateCellRenderer';
import { FaIcon } from '../../../Components/FaIcon';
import { faCalendar, faFileImport, faList } from '@fortawesome/free-solid-svg-icons';
import { Import } from './Import';
import { Month } from '../../../Utils/csv';
import 'moment/locale/de';
import { useAreasQuery, useEventsQuery, useTypesQuery, useYearsQuery } from '../../../generated/graphql';
import { Type, Area } from '../../../Utils/csv';
import { Event } from '../../../Utils/cal';
import { DataContext } from '../../../Context/DataContext';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import Loader from '../../../Components/Loader';
import { EventList } from './EventList';

const { Text } = Typography;

export const CalendarComponent: React.FC = () => {
  const { me } = useContext(DataContext);
  const { data: eventsData, refetch: refetchEvents, loading: loadingEvents } = useEventsQuery({ fetchPolicy: "network-only" });
  const { data: yearsData, refetch: refetchYears } = useYearsQuery({ fetchPolicy: "network-only" });
  const { data: typesData } = useTypesQuery();
  const { data: areasData } = useAreasQuery();

  const [open, set] = useState(false);
  const [view, setView] = useState<'list' | 'calendar'>('calendar');
  const [lang, setLang] = useState<'de' | 'en'>('de');
  const [day, setDay] = useState();
  const [importData, setImportdata] = useState<Month[]>();
  const [events, setEvents] = useState<Event[]>([]);
  const [actualDate, setActualDate] = useState(null);
  const [actualTypes, setType] = useState<string[]>();
  const [actualArea, setArea] = useState<string>();
  const [types, setTypes] = useState<Type[]>();
  const [areas, setAreas] = useState<Area[]>();
  const [range, setRange] = useState<[Moment, Moment]>();
  const [years, setYears] = useState<number[]>();

  const onPanelChange = (value) => {
    setActualDate(value);
  }

  const handleSelect = (sel) => {
    setActualDate(sel);
    setDay(sel.format('DD'));
  }

  const handleLang = (language: 'de' | 'en') => {
    setLang(language);
  }

  const handleReset = () => {
    refetchEvents();
    refetchYears();
  }

  const filter = () => {
    let filteredEvents: Event[] = [];
    if (actualTypes && actualTypes.length > 0 && !actualArea) {
      filteredEvents = eventsData.events.filter(event => actualTypes.includes(event.type.id));
    } else if (actualArea && (!actualTypes || actualTypes.length === 0) ) {
      filteredEvents = eventsData.events.filter(event => event.areas.filter(a => a.id === actualArea).length > 0);
    } else if ((actualTypes && actualTypes.length > 0) && actualArea) {
      const fiteredByArea = eventsData.events.filter(event => event.areas.filter(a => a.id === actualArea).length > 0);
      filteredEvents = fiteredByArea.filter(event => actualTypes.includes(event.type.id));
    } else {
      filteredEvents = eventsData.events;
    }

    setEvents(filteredEvents.filter(e => e.year.value === parseInt(actualDate.format('YYYY'))))
  }

  useEffect(() => {
    if (typesData) {
      setTypes(typesData.types);
    }
  }, [typesData])

  useEffect(() => {
    if (areasData) {
      setAreas(areasData.areas);
    }
  }, [areasData])

  useEffect(() => {
    if (yearsData) {
      setYears(yearsData.years.map(y => y.value).sort((a, b) => a - b));
      setActualDate(moment().set("year", 2021))
    } else {
      setYears([parseInt(moment().format('YYYY')) + 1])
      setActualDate(moment().set("year", 2021))
    }
  }, [yearsData]);

  useEffect(() => {
    if (eventsData && typesData) {
      filter();
    }
  }, [actualArea, actualTypes, eventsData, yearsData, typesData, actualDate]) // eslint-disable-line

  useEffect(() => {
    if (actualDate) {
      setRange([
        moment().set({year: actualDate.format('YYYY'), month: 0, date: 0}),
        moment().set({year: actualDate.format('YYYY'), month: 11, date: moment().month(11).daysInMonth()})
      ]);
    }
  }, [actualDate])

  const copyLink = <Text copyable={{
    text: `${window.location.origin}/cex/${me.id}`,
    tooltips: ['URL Kopieren', 'Kopiert!'],
    icon: <FaIcon icon={faClipboard} />
  }}></Text>

  return (
    <Card
      title={<div>Abfall Kalendar {me && (me.role === "Admin" || me.role === "PaidCustomer") ? copyLink : null}</div>}
      headStyle={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#003363"
      }}
      extra={
        <div>
          <Tooltip title={`Anzeigen als ${view === 'list' ? 'Kalendar' : 'Liste'}`}>
            <Button shape={"circle"} className={styles.button} type={"ghost"} onClick={() => {
              const newView = view === 'calendar' ? 'list' : 'calendar';
              setView(newView);
            }}>
              {view === 'list' ? <FaIcon icon={faCalendar} /> : <FaIcon icon={faList} />}
            </Button>
          </Tooltip>
          <Tooltip title={'Import'}>
            <Button shape={"circle"} className={styles.button} type={"primary"} onClick={() => set(c => !c)}>
              {!open ? <FaIcon icon={faFileImport} /> : <CloseOutlined translate={'no'} />}
            </Button>
          </Tooltip>
        </div>
      }
      className={styles.content}
      bordered={false}
    >
      {open && <Import set={setImportdata} importData={importData} reset={handleReset} />}
      {view === 'calendar' && (
        <div className={styles.calendar}>
          {eventsData && !loadingEvents ? (
            <Calendar
              locale={lang === 'de' ? localeDe : localeEn}
              value={actualDate}
              mode={'month'}
              validRange={range}
              headerRender={({ value, onChange }) => (
                <CalendarHeader
                  setLang={handleLang}
                  years={years}
                  lang={lang}
                  value={value}
                  changeHandler={onChange}
                  actualDate={actualDate}
                  actualDay={day}
                  types={types}
                  areas={areas}
                  actualType={actualTypes}
                  actualArea={actualArea}
                  setType={setType}
                  setArea={setArea}
                  events={events}
                  reset={handleReset}
                />
              )}
              onPanelChange={onPanelChange}
              onChange={handleSelect}
              dateFullCellRender={(value) => (
                <DateCellRenderer
                  value={value}
                  data={events}
                  actualDate={actualDate}
                  reset={handleReset}
                />
              )}
            />
          ) : <div style={{paddingTop: '3rem', paddingBottom: '3rem'}}><Loader /></div>}
        </div>
      )}
      {view === 'list' && (
        <Suspense fallback={<Loader />}>
          {!loadingEvents && events && typesData && areasData && (
            <EventList
              loading={loadingEvents}
              events={events}
              types={types}
              areas={areas}
              reset={handleReset}

              actualType={actualTypes}
              actualArea={actualArea}
              setType={setType}
              setArea={setArea}
            />
          )}
        </Suspense>
      )}
    </Card>
  );
}
