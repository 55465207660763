import React from 'react';
import { Input, Space, Button } from 'antd';
import {
  SearchOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

export type DropDownType = {
  setSelectedKeys: any,
  selectedKeys: any,
  confirm: any,
  clearFilters: any
}

export const columnSearchProps = (
  dataIndex: any,
  searchRef: any,
  column: any,
  query: any,
  setColumn: any,
  setQuery: any
) => {
  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setQuery(selectedKeys[0]);
    setColumn(dataIndex);
  };
  const handleReset = (clearFilters: any) => {
    clearFilters();
    setQuery("")
    setColumn("")
  };
  return ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: DropDownType) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchRef}
            placeholder={`${dataIndex} durchsuchen`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined translate={"no"} />}
              size="small"
              style={{ width: 90 }}
            >
              Suchen
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered: any) => <SearchOutlined translate={"no"} style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) => {
      const splitArray = dataIndex.split('.')
      if (splitArray.length > 1) {
        return record[splitArray[0]][splitArray[1]].toString().toLowerCase().includes(value.toLowerCase());
      } else {
        return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchRef!.current!.select());
      }
    },
    render: (text: string) => {
      const splitArray = column.split('.')
      return splitArray[0] === dataIndex ?
        (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[query]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
    },
  })
};
